import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import axios from './AxiosInstance';
import { AuthContext } from './AuthContext';
import Chart from 'react-apexcharts';
import { CSSTransition } from 'react-transition-group';
import '../assets/styles/Home.css';

// Função para gerar uma sequência de datas
function generateDateSeries(count) {
  const dates = [];
  let currentDate = new Date();
  for (let i = 0; i < count; i++) {
    dates.unshift(
      new Date(currentDate).toLocaleDateString("en-US", { day: "numeric", month: "short" })
    );
    currentDate.setDate(currentDate.getDate() - 1);
  }
  return dates;
}

// Função para gerar dados aleatórios para o gráfico
function generateData(count, strength) {
  const data = [];
  for (let i = 0; i < count; i++) {
    data.push(Math.floor(Math.random() * strength) + 1);
  }
  return data;
}

function Home() {
  const { logout, axiosConfigured } = useContext(AuthContext);
  const hasFetched = useRef(false);
  const [inProp, setInProp] = useState(false);

  const verifySession = useCallback(async () => {
    try {
      await axios.get('/api/users/verify');
    } catch (error) {
      logout(false, false);
    }
  }, [logout]);
  
  useEffect(() => {
    if (axiosConfigured && !hasFetched.current) {
      verifySession();
      hasFetched.current = true;
      window.dispatchEvent(new Event('resize'));
    }
  }, [axiosConfigured, verifySession]);
  

  const dateSeries = generateDateSeries(24);

  const sparkOptions = {
    chart: {
      type: 'area',
      height: 160,
      sparkline: { enabled: true },
    },
    stroke: { curve: 'straight' },
    fill: { opacity: 1 },
    xaxis: { 
      type: 'datetime', 
      categories: dateSeries,
      labels: { show: false }
    },
    tooltip: {
      x: {
        format: 'dd MMM'
      }
    },
    colors: ['#DCE6EC'],
  };

  const categoryLabels = ['Vestuário', 'Alimentação', 'Eletrónicos', 'Utilidades', 'Jardinagem'];

  const categorySalesOptions = {
    chart: {
      type: 'donut',
      height: 400,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    labels: categoryLabels,
    colors: [
      '#FF8A80', // Darker Pastel Red
      '#FFAB91', // Darker Pastel Orange
      '#FFD54F', // Darker Pastel Yellow
      '#AED581', // Darker Pastel Green
      '#64B5F6', // Darker Pastel Blue
      '#9575CD', // Darker Pastel Purple
      '#F06292', // Darker Pastel Pink
      '#FFB74D', // Darker Pastel Peach
      '#4FC3F7', // Darker Pastel Sky Blue
      '#BA68C8', // Darker Pastel Lavender
      '#81C784', // Darker Pastel Mint
      '#F4A460', // Darker Pastel Sandy Brown
      '#90CAF9', // Darker Pastel Light Blue
      '#E57373', // Darker Pastel Coral
      '#A5D6A7'  // Darker Pastel Lime
    ],
    /*colors: [
      '#F44F5E', '#E55A89', '#D863B1', '#CA6CD8',
      '#B57BED', '#8D95EB', '#62ACEA', '#4BC3E6'
    ],*/
    title: {
      text: 'Vendas por Categoria',
      align: 'left',
      style: { fontSize: '18px', fontWeight: 'bold', color: '#6B6B6B' },
    },
    legend: {
      show: true,
      position: 'left',
      offsetY: 30,
      itemMargin: {
        vertical: 5,
      },
      formatter: function (label) {
        return label.length > 12 ? label.slice(0, 12) + '...' : label;
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          legend: {
            show: false,
          },
          chart: {
            height: 380,
          },
        },
      },
    ],
  };

  const categorySalesSeries = [44, 55, 41, 17, 15];

  // Configuração para o gráfico de funil dos produtos mais vendidos
  const topProductsFunnelOptions = {
    chart: {
      type: 'bar',
      height: 400,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: '80%',
        isFunnel: true,
      },
    },
    colors: [
      '#F44F5E', '#E55A89', '#D863B1', '#CA6CD8',
      '#B57BED', '#8D95EB', '#62ACEA', '#4BC3E6'
    ],
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        const label = opt.w.globals.labels[opt.dataPointIndex];
        const maxLength = 8 + opt.dataPointIndex * 2; // Define o limite progressivo (8, 10, 12, ...)
        const truncatedLabel = label.length > maxLength ? label.slice(0, maxLength) + '...' : label;
        return truncatedLabel;
      },
      dropShadow: {
        enabled: true,
      },
    },
    title: {
      text: 'Produtos Mais Vendidos',
      align: 'left',
      style: { fontSize: '18px', fontWeight: 'bold', color: '#6B6B6B' },
    },
    xaxis: {
      categories: [
        'Sneaker', 'Comida Processada', 'Congelados', 'Steak BBQ',
        'Carvão', 'Frango', 'Frutas e Vegetais', 'Graõs'
      ],
    },
    tooltip: {
      y: {
        formatter: (val, { w, dataPointIndex }) => {
          return `${w.globals.labels[dataPointIndex]}: ${val}`;
        },
      },
    },
    legend: {
      show: false,
    },
  };
  const topProductsFunnelSeries = [
    {
      name: 'Quantidade',
      data: [200, 330, 548, 740, 880, 990, 1100, 1380],
    },
  ];  

  // Configuração para o gráfico de top 10 fornecedores por volume de compras
  const supplierNames = [
    'Fornecedor A', 'Fornecedor B', 'Fornecedor C', 'Fornecedor D', 
    'Fornecedor E', 'Fornecedor F', 'Fornecedor G', 'Fornecedor H', 
    'Fornecedor I', 'Fornecedor J'
  ];

  const truncatedSupplierNames = supplierNames.map(name => 
    name.length > 13 ? name.slice(0, 13) + '...' : name
  );

  const topSuppliersOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: truncatedSupplierNames,
      labels: {
        formatter: function (val) {
          return val;
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return `R$: ${Number(val).toFixed(2)}`;
        },
      },
      x: {
        formatter: function(_, opts) {
          // Usa o nome completo no tooltip
          return supplierNames[opts.dataPointIndex];
        },
      }
    },
    colors: ['#4BC3E6'],
    title: {
      text: 'Top 10 Fornecedores',
      style: { fontSize: '18px', fontWeight: 'bold', color: '#6B6B6B' },
    },
  };
  
  const topSuppliersSeries = [
    {
      name: 'Volume de Compras',
      data: [300, 250, 210, 180, 160, 140, 120, 110, 105, 95],
    },
  ];

  // Configuração para o gráfico de top 10 clientes por volume de vendas
  const clientNames = [
    'Cliente A', 'Cliente B', 'Cliente C', 'Cliente D', 
    'Cliente E', 'Cliente F', 'Cliente G', 'Cliente H', 
    'Cliente I', 'Cliente J'
  ];

  const truncatedClientNames = clientNames.map(name => 
    name.length > 13 ? name.slice(0, 13) + '...' : name
  );

  const topClientsOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: truncatedClientNames,
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return `R$: ${Number(val).toFixed(2)}`;
        },
      },
      x: {
        formatter: function(_, opts) {
          // Usa o nome completo no tooltip
          return clientNames[opts.dataPointIndex];
        },
      },
    },
    colors: ['#AED581'],
    title: {
      text: 'Top 10 Clientes',
      style: { fontSize: '18px', fontWeight: 'bold', color: '#6B6B6B' },
    },
  };

  const topClientsSeries = [
    {
      name: 'Volume de Vendas',
      data: [500, 450, 400, 380, 350, 300, 280, 250, 200, 150],
    },
  ];

  return (
    <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
    <div id="wrapper">
      <div className="content-area">
        <div className="container-fluid">
          <h3 className="text-right mt-3 mb-3">Dashboards</h3>
          <div className="main">
            {/* Gráficos sparkline */}
            <div className="row sparkboxes mt-4 mb-0">
              <div className="col-md-4">
                <div className="box box1">
                  <Chart
                    options={{
                      ...sparkOptions,
                      title: {
                        text: 'R$ 424,65',
                        offsetX: 30,
                        style: { fontSize: '24px', fontWeight: 'bold', color: '#6B6B6B' },
                      },
                      subtitle: {
                        text: 'Notas de saída',
                        offsetX: 30,
                        style: { fontSize: '14px' },
                      },
                    }}
                    series={[{ name: 'Notas de saída', data: generateData(24, 100) }]}
                    type="area"
                    height={160}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="box box2">
                  <Chart
                    options={{
                      ...sparkOptions,
                      title: {
                        text: 'R$ 235,31',
                        offsetX: 30,
                        style: { fontSize: '24px', fontWeight: 'bold', color: '#6B6B6B' },
                      },
                      subtitle: {
                        text: 'Notas de entrada',
                        offsetX: 30,
                        style: { fontSize: '14px' },
                      },
                    }}
                    series={[{ name: 'Notas de entrada', data: generateData(24, 100) }]}
                    type="area"
                    height={160}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="box box3">
                  <Chart
                    options={{
                      ...sparkOptions,
                      colors: ['#008FFB'],
                      title: {
                        text: 'R$ 189,34',
                        offsetX: 30,
                        style: { fontSize: '24px', fontWeight: 'bold', color: '#6B6B6B' },
                      },
                      subtitle: {
                        text: 'Devoluções de vendas',
                        offsetX: 30,
                        style: { fontSize: '14px' },
                      },
                    }}
                    series={[{ name: 'Devoluções de vendas', data: generateData(24, 100) }]}
                    type="area"
                    height={160}
                  />
                </div>
              </div>
            </div>

            {/* Gráfico de vendas por categoria e funil de produtos mais vendidos */}
            <div className="row mt-4 mb-4">
              <div className="col-md-6">
                <div className="box chart-container">
                  <Chart
                    options={categorySalesOptions}
                    series={categorySalesSeries}
                    type="donut"
                    height={400}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div className="box chart-container">
                  <Chart
                    options={topProductsFunnelOptions}
                    series={topProductsFunnelSeries}
                    type="bar"
                    height={400}
                  />
                </div>
              </div>
            </div>

            {/* Gráfico de top 10 clientes e fornecedores */}
            <div className="row mt-4 mb-4">
              <div className="col-md-6">
                <div className="box">
                  <Chart
                    options={topClientsOptions}
                    series={topClientsSeries}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div className="box">
                  <Chart
                    options={topSuppliersOptions}
                    series={topSuppliersSeries}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </CSSTransition>
  );
}

export default Home;
