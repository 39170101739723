import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { useRef } from 'react';

const useWebSocket = (subscriptions) => {
  const clientRef = useRef(null);
  let subscriptionsRef = useRef([]);
  let activeUrl = useRef(null); // Variável para armazenar a URL ativa

  const activate = (token) => {
    const newUrl = `${process.env.REACT_APP_BASE_WS}?token=${token}`;

    // Comparar a URL armazenada com a nova URL
    if (activeUrl.current !== newUrl || clientRef.current.forceReconnect === true) {
      if (clientRef.current && clientRef.current.active) {
        clientRef.current.deactivate();
      }

      clientRef.current = new Client({
        webSocketFactory: () => {
          activeUrl.current = newUrl;
          return new SockJS(newUrl);
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        onWebSocketClose: (event) => {
          
          // Verifique a causa do fechamento e desconecte de forma limpa
          if (event.wasClean === false || [1001, 1006, 1011].includes(event.code)) {
            console.warn('Conexão WebSocket fechada pelo servidor', event.code);
              clientRef.current.forceReconnect = true;
              deactivate();
          }
        },
        onConnect: () => {
          clientRef.current.forceReconnect = false;

          subscriptions.forEach(({ topic, callback }) => {
            const subscription = clientRef.current.subscribe(topic, (message) => {
              if (callback) callback(JSON.parse(message.body));
            });
            subscriptionsRef.current.push(subscription);
          });
        },
        onDisconnect: () => {
          //console.log('Desconectado do WebSocket');
        },
        onStompError: (frame) => {
          console.error('Erro STOMP', frame);
        },
      });

      clientRef.current.activate();
    }
  };

  const deactivate = () => {
    if (clientRef.current && clientRef.current.active) {
      clientRef.current.deactivate();
      subscriptionsRef.current = [];
    } else {
        subscriptionsRef = [];
        activeUrl = null;
    }
  };

  return { activate, deactivate, getClient: () => clientRef? clientRef.current: null };
};

export default useWebSocket;

