import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Alert = () => {
  const { alert, setAlert } = useContext(AuthContext);
  const location = useLocation();
  const [alerts, setAlerts] = useState([]);
  const TIMEOUT_ALERT = 6000;

  useEffect(() => {
    if (alert.message) {
      const newAlert = { ...alert, id: new Date().getTime() };
      setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
      setAlert({ type: '', message: '' });
    }
  }, [alert, setAlert]);

  useEffect(() => {
    alerts.forEach((alert) => {
      const timer = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id));
      }, TIMEOUT_ALERT);

      return () => clearTimeout(timer);
    });
  }, [alerts]);

  if (location.pathname === '/login') return null;

  const closeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== id));
  };

  return (
    <div>
      {alerts.map((alert, index) => (
        <div
          key={alert.id}
          className={`alert alert-${alert.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: 'fixed',
            top: `${95 + index * 95}px`,
            right: '20px',
            zIndex: 1060,
            maxWidth: window.innerWidth > 768 ? '500px' : window.innerWidth > 500 ? '300px': '250px',
            wordWrap: 'break-word'
          }}
        >
          {alert.message}
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => closeAlert(alert.id)}
          ></button>
        </div>
      ))}
    </div>
  );
  
};

export default Alert;
