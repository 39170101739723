import { useEffect } from 'react';
import { useNotificationWebSocket } from './AuthContext';

const NotificationWebSocket = () => {
  const notificationData = useNotificationWebSocket();

  useEffect(() => {
    if (notificationData) {
      console.log("Recebido Notification:", notificationData);
      alert(`Mensagem notification: ${notificationData?.mensagem}`);
    }
  }, [notificationData]);
  
  return '';
};

export default NotificationWebSocket;

