import React, { useRef, useState, useEffect, useContext } from 'react';
import { Tab, Tabs, Modal, Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faTrash, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import { AuthContext } from './AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/App.css';
import AsyncSelect2 from './AsyncSelect2';
import ReactInputMask from 'react-input-mask';

function ConfirmationModal({ show, onHide, onConfirm, title, message }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancelar</Button>
                <Button variant="danger" onClick={onConfirm}>Excluir</Button>
            </Modal.Footer>
        </Modal>
    );
}

function CompanySettings() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        companyType: '',
        name: '',
        legalName: '',
        cnpj: '',
        cpf: '',
        stateRegistration: '',
        street: '',
        complement: '',
        number: '',
        neighborhood: '',
        zipCode: '',
        phone: '',
        cellPhone: '',
        email: '',
        website: '',
        active: true,
        taxpayerSecurityCode: '',
        taxpayerSecurityCodeID: '',
        city: '',
        cnaes: [],
        certificateA1Password: '',
        certificateFileName: '',
        certificateFileSize: '',
        certificateFileDeleted: false,
        taxRegime: '1', // Inicializa com 'Simples Nacional'
        monthlyFiscalEmail: '',
        nfeEnvironment: '',
        nfeSeries: '',
        nfeNextNumber: '',
        nfcEnvironment: '',
        nfceSeries: '',
        nfceNextNumber: '',
        automaticManifestation: false
    });
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCnae, setSelectedCnae] = useState(null);
    const [cnaesList, setCnaesList] = useState([]);
    const [cnaesToAdd, setCnaesToAdd] = useState([]);
    const [cnaesToRemove, setCnaesToRemove] = useState([]);
    const [certificateFile, setCertificateFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState('general');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showCertificateConfirmationModal, setShowCertificateConfirmationModal] = useState(false);
    const [cnaeToDelete, setCnaeToDelete] = useState(null);
    const [defaultCnae, setDefaultCnae] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isAutomaticManifestation, setIsAutomaticManifestation] = useState(false);
    const hasFetched = useRef(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const handleShowCertificateConfirmationModal = () => {
        setShowCertificateConfirmationModal(true);
    };

    const handleConfirmDeleteCertificate = () => {
        setShowCertificateConfirmationModal(false);
        setFormData(prevData => ({
            ...prevData,
            certificateFileDeleted: true,
            certificateA1Password: ''
        }));
    };

    const handleShowConfirmationModal = (cnaeId) => {
        setCnaeToDelete(cnaeId);
        setShowConfirmationModal(true);
    };

    const handleConfirmDelete = () => {
        if (cnaeToDelete) {
            setCnaesList(prevList => prevList.filter(cnae => cnae.id !== cnaeToDelete));
            setCnaesToRemove([...cnaesToRemove, cnaeToDelete]);
            setShowConfirmationModal(false);
        }
    };

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            fetchCompanyData();
            hasFetched.current = true;
        }
    }, [axiosConfigured]);

    const fetchCompanyData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/company');
            const company = response.data;

            const city = company.city
                ? { label: `${company.city.name} / ${company.city.uf}`, value: company.city.id, name: company.city.name, uf: company.city.uf }
                : null;

            const defaultCnae = company.defaultCnae ? {
                id: company.defaultCnae.id,
                code: company.defaultCnae.code,
                name: company.defaultCnae.name
            } : null;

            setDefaultCnae(defaultCnae);

            const cnaes = company.cnaes.map(cnae => ({
                id: cnae.id,
                code: cnae.code,
                name: cnae.name
            }));

            setFormData({
                ...company,
                companyType: company.companyType || '',
                city: city ? { id: city.value, name: city.name, uf: city.uf } : null,
                cnaes: company.cnaes.map(cnae => cnae.id),
                certificateA1Password: company.certificateA1Password || '',
                certificateFileName: company.certificateFileName,
                certificateFileSize: company.certificateFileSize,
                certificateFileDeleted: false,
                taxRegime: company.taxRegime || '1',
                monthlyFiscalEmail: company.monthlyFiscalEmail || '',
                nfeEnvironment: company.nfeEnvironment || '',
                nfeSeries: company.nfeSeries || '',
                nfeNextNumber: company.nfeNextNumber || '',
                nfcEnvironment: company.nfcEnvironment || '',
                nfceSeries: company.nfceSeries || '',
                nfceNextNumber: company.nfceNextNumber || '',
                stateRegistration: company.stateRegistration || '',
                automaticManifestation: company.automaticManifestation || ''
            });

            setSelectedCity(city);
            setCnaesList(cnaes);
            setIsAutomaticManifestation(company.automaticManifestation === true);
        } catch (error) {
            console.error('Erro ao buscar dados da conta/loja', error);
        } finally {
            setLoading(false);
        }
    };

    const validateRequiredFields = () => {
        const errors = {};

        if (formData.companyType === 'PESSOA_JURIDICA') {
            if (!formData.cnpj) errors.cnpj = true;
        } else {
            if (!formData.cpf) errors.cpf = true;
        }

        if (!formData.legalName) errors.legalName = true;

        if (Object.keys(errors).length > 0) {
            setKey("general");
        }

        if (!formData.city) {
            errors.city = true;
            if (Object.keys(errors).length === 1) {
                setKey("address");
            }
        }

        const countErros = Object.keys(errors).length;

        if (countErros > 0) {
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        }

        //console.log(errors);
        
        return errors;
    };

    const handleSave = async () => {
        const errors = validateRequiredFields();
    
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
    
        setFormErrors({});

        const {
            certificateA1Password,
            certificateFileName,
            certificateFileSize,
            certificateFileDeleted,
            ...companyData
        } = formData;

        // Remover formatação
        companyData.cnpj = companyData.cnpj ? companyData.cnpj.replace(/[^\d]/g, '') : '';
        companyData.cpf = companyData.cpf ? companyData.cpf.replace(/[^\d]/g, '') : '';
        companyData.zipCode = companyData.zipCode ? companyData.zipCode.replace(/[^\d]/g, '') : '';
        companyData.phone = companyData.phone ? companyData.phone.replace(/[^\d]/g, '') : '';
        companyData.cellPhone = companyData.cellPhone ? companyData.cellPhone.replace(/[^\d]/g, '') : '';

        const formDataToSend = new FormData();

        formDataToSend.append('company', JSON.stringify({
            ...companyData,
            defaultCnae: defaultCnae ? { id: defaultCnae.id } : null,
            cnaes: cnaesList.map(cnae => cnae.id),
            cnaesToAdd: cnaesToAdd || [],
            cnaesToRemove: cnaesToRemove || [],
            certificateFileDeleted,
            certificateA1Password: certificateA1Password || '',
            automaticManifestation: isAutomaticManifestation || false
        }));

        if (certificateFile) {
            formDataToSend.append('certificateA1', certificateFile);
        }

        try {
            await axios.put('/api/company', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Atualiza os dados após o salvamento, para garantir que o estado está atualizado.
            await fetchCompanyData();

            setAlert({ type: 'success', message: 'Dados da conta/loja salvos com sucesso' });
            setCnaesToAdd([]);
            setCnaesToRemove([]);
        } catch (error) {
            console.error('Erro ao salvar dados da conta/loja', error);

            if (!error.response?.headers['x-error-message'] &&
                !error.response?.headers['x-warn-message'] &&
                !error.response?.headers['x-info-message']) {
                setAlert({ type: 'danger', message: 'Erro ao salvar dados da conta/loja' });
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'stateRegistration' && value.length > 14) {
            return;
        }

        setFormData((prevData) => {
            const isChanged = prevData[name] !== value;
            if (isChanged) {
            }
            return { ...prevData, [name]: value };
        });
    };

    const handleCityChange = (selectedOption) => {
        const selectedCity = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.name,
            uf: selectedOption.province
        } : null;

        setSelectedCity(selectedOption);
        setFormData((prevData) => {
            return { ...prevData, city: selectedCity };
        });
    };

    const handleCertificateUpload = (e) => {
        setCertificateFile(e.target.files[0]);
    };

    const handleSetDefaultCnae = (cnae) => {
        setDefaultCnae(cnae);
    };

    const handleAddCnae = () => {
        if (!selectedCnae) {
            setAlert({ type: 'warning', message: 'Selecione um CNAE para adicionar' });
            return;
        }

        const [code, ...nameParts] = selectedCnae.label.split(' - ');
        const name = nameParts.join(' - ');

        if (!code || !name) {
            setAlert({ type: 'warning', message: 'Informações de CNAE incompletas' });
            return;
        }

        const newCnae = {
            id: selectedCnae.value,
            code: code,
            name: name
        };

        setCnaesList(prevList => {
            const updatedList = [...prevList, newCnae];
    
            if (updatedList.length === 1) {
                handleSetDefaultCnae(newCnae);
            }
    
            return updatedList;
        });

        setCnaesToAdd([...cnaesToAdd, selectedCnae.value]);
        setSelectedCnae(null);
    };

    const handleAutomaticManifestation = () => {
        setIsAutomaticManifestation(!isAutomaticManifestation);
    };

    return (
        <div className="form-limited-width mt-4">
            <h3 className="mb-3">Configurações da Conta/Loja</h3>
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mt-4">
                    <Tab eventKey="general" title="Dados Gerais">
                        <div className="form-limited-width mt-4">
                            <form>
                                {/* Seção: Informações Básicas */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                    <div className="mb-3">
                                        <label className="form-label">Tipo</label>
                                        <input
                                            type="text"
                                            name="companyType"
                                            readOnly={true}
                                            className="form-control readonly-style"
                                            value={formData.companyType === 'PESSOA_JURIDICA'? 'Pessoa Jurídica': 'Pessoa Física'}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className={formErrors.legalName? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">{formData.companyType === 'PESSOA_JURIDICA' ? 'Razão Social' : 'Nome Completo'} <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="legalName"
                                            value={formData.legalName || ''}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>

                                    {formData.companyType === 'PESSOA_JURIDICA' ? (
                                        <div className="mb-3">
                                            <label className="form-label">Nome</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={formData.name || ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    ) : ''}

                                    <div className={formErrors.cnpj || formErrors.cpf? 'mb-0 has-error' : 'mb-0'}>
                                        <label className="form-label">{formData.companyType === 'PESSOA_JURIDICA'? 'CNPJ': 'CPF'} <span className="text-danger">*</span></label>
                                        {formData.companyType === 'PESSOA_JURIDICA' ? (
                                            <ReactInputMask mask="99.999.999/9999-99" value={formData.cnpj || ''} onChange={handleInputChange}>
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cnpj" required />}
                                            </ReactInputMask>
                                        ) : (
                                            <ReactInputMask mask="999.999.999-99" value={formData.cpf || ''} onChange={handleInputChange}>
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cpf" required />}
                                            </ReactInputMask>
                                        )}
                                    </div>
                                </div>

                                {/* Seção: Contato */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Contato</h5>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label className="form-label">Telefone</label>
                                            <ReactInputMask mask="(99) 9999-9999" value={formData.phone || ''} onChange={handleInputChange}>
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="phone" />}
                                            </ReactInputMask>
                                        </div>
                                        <div className="col-md-6 mt-3 mt-md-0">
                                            <label className="form-label">Celular</label>
                                            <ReactInputMask mask="(99) 99999-9999" value={formData.cellPhone || ''} onChange={handleInputChange}>
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cellPhone" />}
                                            </ReactInputMask>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            value={formData.email || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="mb-0">
                                        <label className="form-label">Website</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="website"
                                            value={formData.website || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                >
                                    <FontAwesomeIcon icon={faSave} /> Salvar
                                </button>
                            </form>
                        </div>
                    </Tab>

                    <Tab eventKey="address" title="Endereço">
                        <div className="form-limited-width mt-4">
                            <form>
                                {/* Seção: Endereço */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Endereço</h5>
                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            <label className="form-label">Logradouro</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="street"
                                                value={formData.street || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <label className="form-label">Número</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="number"
                                                value={formData.number || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            <label className="form-label">Bairro</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="neighborhood"
                                                value={formData.neighborhood || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <label className="form-label">CEP</label>
                                            <ReactInputMask mask="99999-999" value={formData.zipCode || ''} onChange={handleInputChange}>
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="zipCode" />}
                                            </ReactInputMask>
                                        </div>
                                    </div>

                                    <div className="row mb-0">
                                        <div className={formErrors.city? 'col-md-8 has-error' : 'col-md-8'}>
                                            <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                            <AsyncSelect2
                                                url="/api/cities"
                                                value={selectedCity || ''}
                                                onChange={handleCityChange}
                                                valueField="ibgeCode"
                                                labelField="name"
                                                placeholder="Selecione a cidade"
                                                initialOptions={selectedCity ? [selectedCity] : []}
                                                labelFormatter={(item) => `${item.name} / ${item.province.uf}`}
                                                showClear
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <label className="form-label">Complemento</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="complement"
                                                value={formData.complement || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                >
                                    <FontAwesomeIcon icon={faSave} /> Salvar
                                </button>
                            </form>
                        </div>
                    </Tab>

                    <Tab eventKey="security" title="Segurança">
                        <div className="form-limited-width mt-4">
                            <form autoComplete="off">
                                {/* Seção Código de Segurança do Contribuinte */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Código de Segurança do Contribuinte</h5>
                                    <div className="mb-3">
                                        <label className="form-label">Código de Segurança do Contribuinte</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="taxpayerSecurityCode"
                                            value={formData.taxpayerSecurityCode || ''}
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">ID do Código de Segurança do Contribuinte</label>
                                        <input type="text" style={{ display: 'none' }} autoComplete="username" />
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="taxpayerSecurityCodeID"
                                            value={formData.taxpayerSecurityCodeID || ''}
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="mb-0">
                                        <label className="form-label">Email para envio mensal dos cupons fiscais</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="monthlyFiscalEmail"
                                            value={formData.monthlyFiscalEmail || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                {/* Seção Certificado A1 */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Configuração do Certificado</h5>
                                    <div className="mb-3">
                                        <label className="form-label">Certificado A1 (.pfx)</label>
                                        {formData.certificateFileName && !formData.certificateFileDeleted ? (
                                            <div>
                                                <p>Arquivo atual: {formData.certificateFileName}</p>
                                                {formData.certificateValidity && (
                                                    <p>Validade: {new Date(formData.certificateValidity).toLocaleDateString()}</p>
                                                )}
                                                <button type="button" className="btn btn-danger" onClick={handleShowCertificateConfirmationModal}>
                                                    Excluir Certificado
                                                </button>
                                            </div>
                                        ) : (
                                            <input
                                                type="file"
                                                className="form-control"
                                                accept=".pfx"
                                                onChange={handleCertificateUpload}
                                                autoComplete="off"
                                            />
                                        )}
                                    </div>

                                    <div className="mb-0">
                                        <label className="form-label">Senha do Certificado A1</label>
                                        <div className="input-group">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                className="form-control"
                                                name="certificateA1Password"
                                                value={formData.certificateA1Password || ''}
                                                onChange={handleInputChange}
                                                autoComplete="new-password"
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary"
                                                onClick={togglePasswordVisibility}
                                                style={{ height: "36px", width: "36px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                            >
                                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                >
                                    <FontAwesomeIcon icon={faSave} /> Salvar
                                </button>
                            </form>
                        </div>
                    </Tab>

                    <Tab eventKey="cnaes" title="CNAEs">
                        <div className="form-limited-width mt-4">
                            <form>
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Gerenciar CNAEs</h5>
                                    <div className="d-flex">
                                        <div style={{ flexGrow: 1 }}>
                                            <AsyncSelect2
                                                url="/api/cnae-subclasses"
                                                value={selectedCnae || ''}
                                                onChange={(cnae) => setSelectedCnae(cnae)}
                                                valueField="id"
                                                labelField="name"
                                                labelFormatter={(item) => `${item.code} - ${item.name}`}
                                                placeholder="Selecione o CNAE"
                                                initialOptions={[]}
                                            />
                                        </div>
                                        <Button className="ms-2" onClick={handleAddCnae}>
                                            <FontAwesomeIcon icon={faPlus} /> Adicionar
                                        </Button>
                                    </div>
                                    {cnaesList.length > 0 && (
                                        <>
                                            <h5 style={{marginBottom: 1 + 'em'}} className="mt-4">Registrados</h5>
                                            <ListGroup>
                                                {cnaesList.map((cnae) => (
                                                    <ListGroup.Item key={cnae.id} className="d-flex justify-content-between align-items-center">
                                                        {cnae.code ? `${cnae.code} - ${cnae.name}` : 'Informações não disponíveis'}
                                                        <div>
                                                            <Button
                                                                variant={defaultCnae && defaultCnae.id === cnae.id ? "success" : "outline-secondary"}
                                                                size="sm"
                                                                onClick={() => handleSetDefaultCnae(cnae)}
                                                            >
                                                                {defaultCnae && defaultCnae.id === cnae.id ? "Padrão" : "Definir como Padrão"}
                                                            </Button>
                                                            <Button variant="danger" size="sm" className="ms-2" onClick={() => handleShowConfirmationModal(cnae.id)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </>
                                    )}

                                    <ConfirmationModal
                                        show={showConfirmationModal}
                                        onHide={() => setShowConfirmationModal(false)}
                                        onConfirm={handleConfirmDelete}
                                        title="Confirmar Exclusão"
                                        message="Tem certeza de que deseja excluir este CNAE?"
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                >
                                    <FontAwesomeIcon icon={faSave} /> Salvar
                                </button>
                            </form>
                        </div>
                    </Tab>

                    <Tab eventKey="fiscal" title="Dados Fiscais">
                        <div className="form-limited-width mt-4">
                            <form>
                                {/* Seção Regime Tributário */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Informações fiscais</h5>
                                    <div className="mb-3">
                                        <label className="form-label">Regime tributário</label>
                                        <select
                                            className="form-select"
                                            name="taxRegime"
                                            value={formData.taxRegime || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="1">Simples Nacional</option>
                                        </select>
                                    </div>
                                    <div className="mb-0">
                                        <label className="form-label">Inscrição estadual</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="stateRegistration"
                                            value={formData.stateRegistration || ''}
                                            onChange={handleInputChange}
                                            max="99999999999999"
                                        />
                                    </div>
                                </div>

                                {/* Seção NF-e */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>NF-e</h5>

                                    <div className="mb-3">
                                        <label className="form-label">Ambiente <span className="text-danger">*</span></label>
                                        <select 
                                            className="form-select"
                                            name="nfeEnvironment"
                                            value={formData.nfeEnvironment}
                                            onChange={handleInputChange}
                                            required={true}
                                        >
                                            <option value="HOMOLOGACAO">Homologação</option>
                                            <option value="PRODUCAO">Produção</option>
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Série NFe</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nfeSeries"
                                            value={formData.nfeSeries || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Próxima numeração NFe</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nfeNextNumber"
                                            value={formData.nfeNextNumber}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className='mb-0 d-flex justify-content-end'>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="automaticManifestation"
                                                checked={isAutomaticManifestation}
                                                onChange={handleAutomaticManifestation}
                                            />
                                            <label className="form-check-label" htmlFor="automaticManifestation">
                                                Manifestar ciência da operação automáticamente nas notas fiscais de entrada
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Seção NFC-e */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>NFC-e</h5>

                                    <div className="mb-3">
                                        <label className="form-label">Ambiente <span className="text-danger">*</span></label>
                                        <select 
                                            className="form-select"
                                            name="nfcEnvironment"
                                            value={formData.nfcEnvironment}
                                            onChange={handleInputChange}
                                            required={true}
                                        >
                                            <option value="HOMOLOGACAO">Homologação</option>
                                            <option value="PRODUCAO">Produção</option>
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Série NFCe</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nfceSeries"
                                            value={formData.nfceSeries || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="mb-0">
                                        <label className="form-label">Próxima numeração NFCe</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nfceNextNumber"
                                            value={formData.nfceNextNumber || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                >
                                    <FontAwesomeIcon icon={faSave} /> Salvar
                                </button>
                            </form>
                        </div>
                    </Tab>
                </Tabs>
            )}
            {/* Modal para confirmar exclusão de certificado */}
            <ConfirmationModal
                show={showCertificateConfirmationModal}
                onHide={() => setShowCertificateConfirmationModal(false)}
                onConfirm={handleConfirmDeleteCertificate}
                title="Confirmar Exclusão"
                message="Tem certeza de que deseja excluir o certificado A1?"
            />
        </div>
    );
}

export default CompanySettings;

