import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/App.css';
import ReactInputMask from 'react-input-mask';
import ConfirmationCnpj from './ConfirmationCnpj';
import { CSSTransition } from 'react-transition-group';

function CarrierEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        carrierType: 'PESSOA_JURIDICA',
        name: '',
        legalName: '',
        cnpj: '',
        stateRegistration: '',
        phone: '',
        cellPhone: '',
        email: '',
        website: '',
        active: true,
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        zipCode: '',
        city: null,
        simplesNacional: false
    });
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [selectedCity, setSelectedCity] = useState(null);
    const [showConfirmCnpj, setShowConfirmCnpj] = useState(false);
    const [cnpjToConfirm, setCnpjToConfirm] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { carrier: initialCarrier, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [carrier, setCarrier] = useState(initialCarrier || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);
    const lastValidatedCnpj = useRef('');

    const loadCarrier = useCallback(async () => {
        try {
            const response = await axios.get(`/api/carriers/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar transportadora:', error);
            if (error.response && error.response.status === 404) {
                navigate('/carriers', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadCarrier();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setFormData((prevFormData) => ({
                ...prevFormData,
                carrierType: 'PESSOA_JURIDICA'
            }));
            setShowForm(true);
        }
    }, [axiosConfigured, id, loadCarrier]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const initializeForm = (carrier) => {
        setCarrier(carrier);
        setFormData({
            carrierType: carrier.carrierType || 'PESSOA_JURIDICA',
            name: carrier.name || '',
            legalName: carrier.legalName || '',
            cnpj: carrier.cnpj || '',
            cpf: carrier.cpf || '',
            stateRegistration: carrier.stateRegistration || '',
            phone: carrier.phone || '',
            cellPhone: carrier.cellPhone || '',
            email: carrier.email || '',
            website: carrier.website || '',
            active: carrier.active || 'true',
            street: carrier.street || '',
            number: carrier.number || '',
            complement: carrier.complement || '',
            neighborhood: carrier.neighborhood || '',
            zipCode: carrier.zipCode || '',
            city: carrier.city || null,
            simplesNacional: carrier.isSimples? carrier.isSimples: carrier.simplesNacional || 'false'
        });
        setSelectedCity(carrier.city ? {
            label: `${carrier.city.name} / ${carrier.city.uf}`,
            value: carrier.city.id || '',
            name: carrier.city.name || '',
            province: carrier.city.uf || ''
        } : null);
    };

    const handleCnpj = useCallback(async (cnpj) => {
        try {
            const response = await axios.get(`/api/cnpj/${cnpj}`);
            if (response.data) {
                confirmCnpj(response.data);
                setIsFormChanged(true);
            } else {
                response.data.cnpj = cnpj;
            }
            if (id !== "new") {
                response.data.id = id;
            }
        } catch (error) {
            //console.info("Não foi possível consultar o CNPJ");
        }
    }, [id]);

    const validateRequiredFields = () => {
        const errors = {};

        if (formData.carrierType === 'PESSOA_JURIDICA') {
            if (!formData.cnpj) errors.cnpj = true;
        } else {
            if (!formData.cpf) errors.cpf = true;
        }
        
        if (!formData.legalName) errors.legalName = true;
        if (!selectedCity) errors.city = true;

        const countErros = Object.keys(errors).length;

        if (countErros > 0) {
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        }

        //console.log(errors);
        
        return errors;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let cnpj = '';
        let parsedValue = value;

        if (name === 'stateRegistration' && value.length > 14) {
            return;
        }

        if (name === 'simplesNacional' || name === 'active') {
            parsedValue = value === 'true';
        }
    
        setFormData((prevData) => {
            const isChanged = prevData[name] !== parsedValue;

            if (name === 'carrierType' && isChanged) {
                setIsFormChanged(true);
                return {
                    ...prevData,
                    carrierType: parsedValue,
                    cnpj: '',
                    cpf: '',
                    legalName: '',
                    name: ''
                };
            }
    
            if (name === "cnpj" && isChanged) {
                cnpj = removeFormatting(value);
                if (cnpj.length === 14 && cnpj !== lastValidatedCnpj.current) {
                    lastValidatedCnpj.current = cnpj;
                    handleCnpj(cnpj);
                }
            }
    
            if (isChanged) {
                setIsFormChanged(true);
            }

            return { ...prevData, [name]: parsedValue };
        });
    };

    const confirmCnpj = (cnpjToConfirm) => {
        setCnpjToConfirm(cnpjToConfirm);
        setShowConfirmCnpj(true);
    };

    const handleSelectCnpj = async (carrier) => {
        carrier.phone = carrier.phone1;
        initializeForm(carrier);
        setShowConfirmCnpj(false);
        setIsFormChanged(true);

    };

    const handleCityChange = (selectedOption) => {
        const selectedCity = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.name,
            uf: selectedOption.province
        } : null;
        
        setSelectedCity(selectedOption);
        setFormData((prevData) => {
            const isChanged = prevData.city !== selectedCity;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, city: selectedCity };
        });
    };

    function removeFormatting(value) {
        if (!value) {
            return '';
        }
        return value.replace(/[^\d]/g, '');
    }

    const handleSave = async () => {
        const errors = validateRequiredFields();
    
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
    
        setFormErrors({});
        setIsFormChanged(false);

        try {
            const dataToSend = {
                ...formData,
                city: selectedCity ? {
                    id: selectedCity.value,
                    name: selectedCity.name,
                    uf: selectedCity.province
                } : null,
                simplesNacional: formData.simplesNacional === true || formData.simplesNacional === 'true'
            };

            dataToSend.cnpj = removeFormatting(dataToSend.cnpj);
            dataToSend.cpf = removeFormatting(dataToSend.cpf);
            dataToSend.zipCode = removeFormatting(dataToSend.zipCode);
            dataToSend.phone = removeFormatting(dataToSend.phone);
            dataToSend.cellPhone = removeFormatting(dataToSend.cellPhone);
    
            if (carrier.id) {
                await axios.put(`/api/carriers/${carrier.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Transportadora editada com sucesso.' });
            } else {
                await axios.post('/api/carriers', dataToSend);
                setAlert({ type: 'success', message: 'Transportadora adicionada com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/carriers', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
        }
    };
    
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/carriers', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-3">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h3 className="mb-3">{carrier.id ? 'Editar Transportadora' : 'Nova Transportadora'}</h3>
                        <form>
                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                <div className="mb-3">
                                    <label className="form-label">Tipo de Cliente <span className="text-danger">*</span></label>
                                    <div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input
                                            className="form-check-input"
                                            id="pessoaJuridica"
                                            type="radio"
                                            name="carrierType"
                                            value="PESSOA_JURIDICA"
                                            checked={formData.carrierType === 'PESSOA_JURIDICA'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="pessoaJuridica">Pessoa Jurídica</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            id="pessoaFisica"
                                            type="radio"
                                            name="carrierType"
                                            value="PESSOA_FISICA"
                                            checked={formData.carrierType === 'PESSOA_FISICA'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="pessoaFisica">Pessoa Física</label>
                                    </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className={formErrors.cnpj || formErrors.cpf ? 'col-md-4 has-error' : 'col-md-4'}>
                                        <label className="form-label">
                                            {formData.carrierType === 'PESSOA_JURIDICA' ? 'CNPJ' : 'CPF'} <span className="text-danger">*</span>
                                        </label>
                                        {formData.carrierType === 'PESSOA_JURIDICA' ? (
                                            <ReactInputMask mask="99.999.999/9999-99" value={formData.cnpj || ''} onChange={handleInputChange} autoComplete="off">
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cnpj" required />}
                                            </ReactInputMask>
                                        ) : (
                                            <ReactInputMask mask="999.999.999-99" value={formData.cpf || ''} onChange={handleInputChange} autoComplete="off">
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cpf" required />}
                                            </ReactInputMask>
                                        )}
                                    </div>
                                    <div className={formErrors.legalName? 'col-md-8 mt-3 mt-md-0 has-error' : 'col-md-8 mt-3 mt-md-0'}>
                                        <label className="form-label">
                                            {formData.carrierType === 'PESSOA_JURIDICA' ? 'Razão Social' : 'Nome Completo'} <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="legalName"
                                            value={formData.legalName || ''}
                                            onChange={handleInputChange}
                                            required
                                            maxLength={150}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                {formData.carrierType === 'PESSOA_JURIDICA' ? (
                                <div className="mb-3">
                                    <label className="form-label">Nome Fantasia</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={formData.name || ''}
                                        onChange={handleInputChange}
                                        maxLength={100}
                                        autoComplete="off"
                                    />
                                </div>
                                ) : ''}
                                <div className="mb-0">
                                    <label className="form-label">Ativo <span className="text-danger">*</span></label>
                                    <select 
                                        className="form-select"
                                        name="active"
                                        value={formData.active}
                                        onChange={handleInputChange}>
                                        <option value={true}>Sim</option>
                                        <option value={false}>Não</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Endereço</h5>
                                <div className="row mb-3">
                                    <div className="col-md-8">
                                        <label className="form-label">Logradouro</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="street"
                                            value={formData.street || ''}
                                            onChange={handleInputChange}
                                            maxLength={100}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-md-4 mt-3 mt-md-0">
                                        <label className="form-label">Número</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="number"
                                            value={formData.number || ''}
                                            onChange={handleInputChange}
                                            maxLength={10}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-8">
                                        <label className="form-label">Bairro</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="neighborhood"
                                            value={formData.neighborhood || ''}
                                            onChange={handleInputChange}
                                            maxLength={60}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-md-4 mt-3 mt-md-0">
                                        <label className="form-label">CEP</label>
                                        <ReactInputMask mask="99999-999" value={formData.zipCode || ''} onChange={handleInputChange} autoComplete="off">
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" name="zipCode" />}
                                        </ReactInputMask>
                                    </div>
                                </div>

                                <div className="row mb-0">
                                    <div className={formErrors.city? 'col-md-8 has-error' : 'col-md-8'}>
                                        <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                        <AsyncSelect2
                                            url="/api/cities"
                                            value={selectedCity}
                                            onChange={handleCityChange}
                                            valueField="ibgeCode"
                                            labelField="name"
                                            placeholder="Selecione a cidade"
                                            initialOptions={selectedCity ? [selectedCity] : []}
                                            labelFormatter={(item) => `${item.name} / ${item.province.uf}`}
                                            showClear
                                        />
                                    </div>
                                    <div className="col-md-4 mt-3 mt-md-0">
                                        <label className="form-label">Complemento</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="complement"
                                            value={formData.complement}
                                            onChange={handleInputChange}
                                            maxLength={60}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Contato</h5>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label">Telefone</label>
                                        <ReactInputMask 
                                            mask="(99) 9999-9999" 
                                            value={formData.phone || ''} 
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" name="phone" />}
                                        </ReactInputMask>
                                    </div>
                                    <div className="col-md-6 mt-3 mt-md-0">
                                        <label className="form-label">Celular</label>
                                        <ReactInputMask 
                                            mask="(99) 99999-9999" 
                                            value={formData.cellPhone || ''} 
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cellPhone" />}
                                        </ReactInputMask>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        value={formData.email || ''}
                                        onChange={handleInputChange}
                                        maxLength={60}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="mb-0">
                                    <label className="form-label">Website</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="website"
                                        value={formData.website || ''}
                                        onChange={handleInputChange}
                                        maxLength={200}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Informações fiscais</h5>
                                <div className="mb-3">
                                    <label className="form-label">Simples Nacional <span className="text-danger">*</span></label>
                                    <select 
                                        className="form-select"
                                        name="simplesNacional"
                                        value={formData.simplesNacional}
                                        onChange={handleInputChange}
                                    >
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </select>
                                </div>
                                <div className="mb-0">
                                    <label className="form-label">Inscrição estadual</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="stateRegistration"
                                        value={formData.stateRegistration || ''}
                                        onChange={handleInputChange}
                                        max="99999999999999"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                                disabled={!carrier.id? false: !isFormChanged? true: false}
                            >
                                <FontAwesomeIcon icon={faSave} /> Salvar
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                        </form>
                    </div>
                ) : null}

                <ConfirmationCnpj
                    show={showConfirmCnpj}
                    onHide={() => setShowConfirmCnpj(false)}
                    onConfirm={() => handleSelectCnpj(cnpjToConfirm)}
                    cnpjData={cnpjToConfirm}
                />
            </div>
        </CSSTransition>
    );
}

export default CarrierEdit;
