import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function AlertModal({ show, onHide, title, message }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>Ok</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AlertModal;
