import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from './AxiosInstance';
import { InputMask } from 'primereact/inputmask';
import '../assets/styles/App.css';
import registerBackground from '../assets/images/register.webp';

function Register() {
  const [companyType, setCompanyType] = useState('PESSOA_JURIDICA');
  const [companyName] = useState('');
  const [companyLegalName, setCompanyLegalName] = useState('');
  const [companyCNPJ, setCompanyCNPJ] = useState('');
  const [cpf, setCpf] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userConfirmPassword, setUserConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userPassword !== userConfirmPassword) {
      setErrorMessage('As senhas não coincidem.');
      return;
    }

    // Remove a formatação do CNPJ (pontos, barras, traços)
    const formattedCNPJ = companyCNPJ.replace(/[^\d]/g, '');
    const formattedCPF = cpf.replace(/[^\d]/g, '');

    try {
      const dataToSend = {
        companyType,
        companyName,
        companyLegalName,
        companyCNPJ: companyType === 'PESSOA_JURIDICA'? formattedCNPJ: '',
        cpf: companyType === 'PESSOA_FISICA'? formattedCPF: '',
        userName: companyType === 'PESSOA_FISICA'? companyLegalName: userName,
        userEmail,
        userPassword
      }

      await axios.post('/api/users/register', dataToSend);

      setSuccessMessage('Usuário criado com sucesso! Você já pode efetuar login.');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      if (error.response) {
        setErrorMessage('Erro ao registrar. Por favor, verifique os dados e tente novamente.');
      } else if (error.request) {
        setErrorMessage('Serviço indisponível. Por favor, tente novamente mais tarde.');
      } else {
        setErrorMessage('Erro desconhecido. Por favor, tente novamente.');
      }
      console.error('Registration failed', error);
    }
  };

  return (
    <section className="vh-100">
      <div className="container-register py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: '1rem', height: '100%' }}>
              <div className="row g-0 h-100">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src={registerBackground}
                    alt="register form"
                    className="img-fluid h-100" style={{ borderRadius: '1rem 0 0 1rem', objectFit: 'cover' }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">

                    <form onSubmit={handleSubmit}>

                      <div className="d-flex align-items-center mb-3 pb-1">
                        <span className="h2 fw-bold mb-0">Registrar</span>
                      </div>

                      <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>Preencha os dados para criar sua conta</h5>

                      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                      {successMessage && <div className="alert alert-success">{successMessage}</div>}

                      <h6 className="fw-bold mb-3 pb-3" style={{ letterSpacing: '1px' }}>{companyType === 'PESSOA_JURIDICA'? 'Dados da Empresa': 'Dados do Usuário'}</h6>

                      <div className="form-outline mb-3">
                          <label className="form-label">Tipo <span className="text-danger">*</span></label>
                          <div>
                              <div className="form-check form-check-inline mt-1">
                                  <input
                                      className="form-check-input"
                                      id="pessoaJuridica"
                                      type="radio"
                                      name="companyType"
                                      value="PESSOA_JURIDICA"
                                      checked={companyType === 'PESSOA_JURIDICA'}
                                      onChange={(e) => setCompanyType(e.target.value)}
                                      autoComplete="off"
                                  />
                                  <label className="form-check-label" htmlFor="pessoaJuridica">Pessoa Jurídica</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input
                                      className="form-check-input"
                                      id="pessoaFisica"
                                      type="radio"
                                      name="companyType"
                                      value="PESSOA_FISICA"
                                      checked={companyType === 'PESSOA_FISICA'}
                                      onChange={(e) => setCompanyType(e.target.value)}
                                      autoComplete="off"
                                  />
                                  <label className="form-check-label" htmlFor="pessoaFisica">Pessoa Física</label>
                              </div>
                          </div>
                      </div>

                      <div className="form-outline mb-4">
                        <input
                          type="text"
                          id="companyLegalName"
                          className="form-control form-control-lg"
                          value={companyLegalName}
                          onChange={(e) => setCompanyLegalName(e.target.value)}
                          required
                          maxLength={150}
                          autoComplete="off"
                        />
                        <label className="form-label" htmlFor="companyLegalName">{companyType === 'PESSOA_JURIDICA' ? 'Razão Social' : 'Nome'}</label>
                      </div>

                      {companyType === 'PESSOA_JURIDICA' ? (
                        <div className="form-outline mb-4">
                          <InputMask
                            mask="99.999.999/9999-99"
                            id="companyCNPJ"
                            value={companyCNPJ}
                            onChange={(e) => setCompanyCNPJ(e.target.value)}
                            required
                            className="form-control form-control-lg"
                            autoComplete="off"
                          />
                          <label className="form-label" htmlFor="companyCNPJ">CNPJ</label>
                        </div>
                      ) : (
                        <div className="form-outline mb-4">
                          <InputMask
                            mask="999.999.999-99"
                            id="cpf"
                            value={cpf}
                            onChange={(e) => setCpf(e.target.value)}
                            required
                            className="form-control form-control-lg"
                            autoComplete="off"
                          />
                          <label className="form-label" htmlFor="cpf">CPF</label>
                        </div>
                      )}

                      {companyType === 'PESSOA_JURIDICA' ? (
                        <h6 className="fw-bold mb-3 pb-3" style={{ letterSpacing: '1px' }}>Dados do Usuário</h6>
                      ): ''}

                      {companyType === 'PESSOA_JURIDICA' ? (
                      <div className="form-outline mb-4">
                        <input
                          type="text"
                          id="txtuser.Name"
                          className="form-control form-control-lg"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          required
                          maxLength={60}
                          autoComplete="off"
                        />
                        <label className="form-label" htmlFor="txtuser.Name">Nome</label>
                      </div>
                      ) : ''}

                      <div className="form-outline mb-4">
                        <input
                          type="email"
                          id="txtUserEmail"
                          className="form-control form-control-lg"
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                          required
                          maxLength={60}
                          autoComplete="off"
                        />
                        <label className="form-label" htmlFor="email">E-mail</label>
                      </div>

                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="userPassword"
                          className="form-control form-control-lg"
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                          required
                          maxLength={20}
                          autoComplete="off"
                        />
                        <label className="form-label" htmlFor="password">Senha</label>
                      </div>

                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="userConfirmPassword"
                          className="form-control form-control-lg"
                          value={userConfirmPassword}
                          onChange={(e) => setUserConfirmPassword(e.target.value)}
                          required
                          maxLength={20}
                          autoComplete="off"
                        />
                        <label className="form-label" htmlFor="confirmPassword">Confirme a Senha</label>
                      </div>

                      <div className="pt-1 mb-4">
                        <button className="btn btn-dark btn-lg btn-block" type="submit">Registrar</button>
                      </div>

                      <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>Já tem uma conta? <a href="/login" style={{ color: '#393f81' }}>Entre aqui</a></p>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
