import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortUp, faSortDown, faChevronDown, faChevronUp, faEye, faCheckCircle, faTimesCircle, faQuestionCircle, faSpinner, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paginator } from 'primereact/paginator';
import { CSSTransition } from 'react-transition-group';
import ManifestationConfirm from './ManifestationConfirm';
import '../assets/styles/InvoiceStatus.css';

function InvoiceReceivedList() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [invoices, setInvoices] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState(2);
    const [loading, setLoading] = useState(false);
    const [showManifestationConfirmModal, setShowManifestationConfirmModal] = useState(false);
    const [invoiceToManifestation, setInvoiceToManifestation] = useState(null);
    const [eventTypeToManifestation, setEventTypeToManifestation] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const [activeTab, setActiveTab] = useState('processed'); // Default to 'processed' tab
    const hasFetched = useRef(false);
    const location = useLocation();
    const [inProp, setInProp] = useState(false);
    const [loadingManifestationId, setLoadingManifestationId] = useState(null);
    const [previousSearchTerm, setPreviousSearchTerm] = useState('');

    const fetchInvoices = useCallback(async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/invoices/incoming', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const invoiceData = response.data.content || [];

            const invoiceDataToSet = invoiceData.map((invoice) => ({
                ...invoice,
                type: 'processed'
            }));

            setInvoices(invoiceDataToSet);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar notas fiscais', error);
        } finally {
            setLoading(false);
        }
    }, [setInvoices, setTotalRecords, setLoading]);

    const fetchInvoiceSummary = useCallback(async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/invoices/incoming/summary', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const summaryDataList = response.data.content || [];

            const invoicesToSet = summaryDataList.map((summaryData) => ({
                id: summaryData.chave,
                name: summaryData.nome,
                invoiceAmount: summaryData.valorNotaFiscal,
                issueDate: summaryData.dataHoraEmissao,
                number: summaryData.chave,
                manifestations: summaryData.manifestations,
                type: 'summary',
                supplier: {
                    cnpj: summaryData.cnpj,
                    cpf: summaryData.cpf,
                    name: summaryData.nome,
                    legalName: summaryData.nome
                }
            }));

            setInvoices(invoicesToSet);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar resumo de notas fiscais', error);
        } finally {
            setLoading(false);
        }
    }, [setInvoices, setTotalRecords, setLoading]);

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            const state = location.state;
            if (state) {
                setSearchTerm(state.searchTerm || '');
                setPage(state.page || 0);
                setRows(state.rows || 10);
                setSortField(state.sortField || 'id');
                setSortOrder(state.sortOrder || 2);
            }
            if (activeTab === 'processed') {
                fetchInvoices(state?.page || page, state?.rows || rows, state?.sortField || sortField, state?.sortOrder || sortOrder, state?.searchTerm || searchTerm);
            } else if (activeTab === 'summary') {
                fetchInvoiceSummary(state?.page || page, state?.rows || rows, state?.sortField || sortField, state?.sortOrder || sortOrder, state?.searchTerm || searchTerm);
            }
            hasFetched.current = true;
        }
    }, [fetchInvoices, fetchInvoiceSummary, axiosConfigured, location.state, page, rows, searchTerm, sortField, sortOrder, activeTab]);

    const handleSearch = () => {
        if (searchTerm !== previousSearchTerm) {
            setPage(0);
            setPreviousSearchTerm(searchTerm);
            if (activeTab === 'processed') {
                fetchInvoices(0, rows, sortField, sortOrder, searchTerm);
            } else {
                fetchInvoiceSummary(0, rows, sortField, sortOrder, searchTerm);
            }
        } else {
            if (activeTab === 'processed') {
                fetchInvoices(page, rows, sortField, sortOrder, searchTerm);
            } else {
                fetchInvoiceSummary(page, rows, sortField, sortOrder, searchTerm);
            }
        }
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 1 ? -1 : 1;
        setSortField(field);
        setSortOrder(order);

        if (activeTab === 'processed') {
            fetchInvoices(page, rows, field, order, searchTerm);
        } else {
            fetchInvoiceSummary(page, rows, field, order, searchTerm);
        }
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 1 ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const changeTab = (tab) => {
        setPage(0);
        if (tab === 'processed') {
            fetchInvoices(0, rows, 'id', sortOrder, searchTerm);
        } else {
            fetchInvoiceSummary(0, rows, 'id', sortOrder, searchTerm);
        }
    }

    const onPageChange = (event) => {
        setPage(event.page);
        setRows(event.rows);

        if (activeTab === 'processed') {
            fetchInvoices(event.page, event.rows, sortField, sortOrder, searchTerm);
        } else {
            fetchInvoiceSummary(event.page, event.rows, sortField, sortOrder, searchTerm);
        }
    };

    const confirmManifestation = (invoice, eventType) => {
        setInvoiceToManifestation(invoice);
        setEventTypeToManifestation(eventType);
        setShowManifestationConfirmModal(true);
    };

    const handleManifestation = async (justification) => {
        try {
            setLoadingManifestationId(invoiceToManifestation.id);
            const dataToSend = {
                accessKey: invoiceToManifestation.number,
                eventType: eventTypeToManifestation,
                justification: justification
            };
            const response = await axios.post('/api/invoices/incoming/summary/manifestation', dataToSend);

            const updatedInvoice = response.data;

            //console.log(updatedInvoice);

            let invoiceToSet = invoiceToManifestation;

            if (activeTab === 'summary') {
                invoiceToSet = {
                    id: updatedInvoice.chave,
                    name: updatedInvoice.nome,
                    invoiceAmount: updatedInvoice.valorNotaFiscal,
                    issueDate: updatedInvoice.dataHoraEmissao,
                    number: updatedInvoice.chave,
                    manifestations: updatedInvoice.manifestations,
                    type: 'summary',
                    supplier: {
                        cnpj: updatedInvoice.cnpj,
                        cpf: updatedInvoice.cpf,
                        name: updatedInvoice.nome,
                        legalName: updatedInvoice.nome
                    }
                };

                setInvoices((prevInvoices) =>
                    prevInvoices.map((inv) =>
                        inv.id === invoiceToSet.id ? invoiceToSet : inv
                    )
                );
            } else {
                invoiceToSet.manifestations = updatedInvoice.manifestations;

                setInvoices((prevInvoices) =>
                    prevInvoices.map((inv) =>
                        inv.id === invoiceToSet.id ? invoiceToSet : inv
                    )
                );
            }
       
            //console.log(invoiceToSet.manifestation);

            const manifestation = getManifestation(invoiceToSet, eventTypeToManifestation);

            if (manifestation?.status === 135 || manifestation?.status === 573) {
                setAlert({ type: 'success', message: 'Manifestação efetuada com sucesso.' });
            } else {
                setAlert({ type: 'danger', message: manifestation.reason });
            }
            
            setInProp(false);

            setInvoiceToManifestation('');
            setEventTypeToManifestation('');
            setShowManifestationConfirmModal(false);
        } catch (error) {
            setShowManifestationConfirmModal(false);
            setEventTypeToManifestation('');
        } finally {
            setLoadingManifestationId(null);
        }
    };

    const handleManifestationWithoutJustification = async (invoice, eventType) => {
        try {
            setLoadingManifestationId(invoice.id);

            const dataToSend = {
                accessKey: activeTab === 'summary'? invoice.number: invoice.accessKey,
                eventType: eventType,
                justification: ''
            };

            const response = await axios.post('/api/invoices/incoming/summary/manifestation', dataToSend);
        
            const updatedInvoice = response.data;

            //console.log(updatedInvoice);

            let invoiceToSet = invoice;

            if (activeTab === 'summary') {
                invoiceToSet = {
                    id: updatedInvoice.chave,
                    name: updatedInvoice.nome,
                    invoiceAmount: updatedInvoice.valorNotaFiscal,
                    issueDate: updatedInvoice.dataHoraEmissao,
                    number: updatedInvoice.chave,
                    manifestations: updatedInvoice.manifestations,
                    type: 'summary',
                    supplier: {
                        cnpj: updatedInvoice.cnpj,
                        cpf: updatedInvoice.cpf,
                        name: updatedInvoice.nome,
                        legalName: updatedInvoice.nome
                    }
                };

                setInvoices((prevInvoices) =>
                    prevInvoices.map((inv) =>
                        inv.id === invoiceToSet.id ? invoiceToSet : inv
                    )
                );
            } else {
                invoiceToSet.manifestations = updatedInvoice.manifestations;

                setInvoices((prevInvoices) =>
                    prevInvoices.map((inv) =>
                        inv.id === invoiceToSet.id ? invoiceToSet : inv
                    )
                );
            }
       
            //console.log(invoiceToSet.manifestation);

            const manifestation = getManifestation(invoiceToSet, eventType);

            if (manifestation?.status === 135 || manifestation?.status === 573) {
                setAlert({ type: 'success', message: 'Manifestação efetuada com sucesso.' });
            } else {
                setAlert({ type: 'danger', message: manifestation.reason });
            }
            
            setInProp(false);
        } catch (error) {
            console.error(error);
            console.error('Erro na manifestação');
        } finally {
            setLoadingManifestationId(null);
        }
    };

    const toggleRowExpansion = (invoiceId) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(invoiceId)
                ? prevExpandedRows.filter((id) => id !== invoiceId)
                : [...prevExpandedRows, invoiceId]
        );
    };

    const getManifestation = (invoice, eventType) => {
        if (!invoice || !invoice.manifestations) return null;
    
        // Primeiro, procure uma manifestação com status 135 e eventType escolhido
        const manifestationWithStatus135 = invoice.manifestations.find(manifestation =>
            manifestation.eventType === eventType && manifestation.status === 135
        );
    
        if (manifestationWithStatus135) {
            return manifestationWithStatus135;
        }
    
        // Se não encontrar, pegue a última da lista com o eventType escolhido
        const manifestationsWithEventType = invoice.manifestations
            .filter(manifestation => manifestation.eventType === eventType);
    
        return manifestationsWithEventType.length > 0 
            ? manifestationsWithEventType[manifestationsWithEventType.length - 1]
            : null;
    };

    const getManifestationClass = (invoice, eventType) => {
        if (!invoice || !invoice.manifestations) return null;
        const manifestation = getManifestation(invoice, eventType);

        if (manifestation?.status === 135) {
            return 'me-1 success-style';
        } else if (manifestation?.status && manifestation?.status !== 135) {
            return 'me-1 error-style';
        } else {
            return 'me-1 readonly-style';
        }
    };

    const getManifestationTitle = (invoice, eventType) => {
        if (!invoice || !invoice.manifestations) return '';
        const manifestation = getManifestation(invoice, eventType);

        if (manifestation?.status === 135) {
            return 'Manifestação enviada';
        } else if (manifestation?.status && manifestation?.status !== 135) {
            return 'Manifestação rejeitada. ' + manifestation.reason;
        } else {
            return '';
        }
    };

    const isRowExpanded = (invoiceId) => expandedRows.includes(invoiceId);

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
            <div>
                <h3 className="mb-3 mt-3">Notas Fiscais de Entrada</h3>

                <div className="d-flex justify-content-between mb-3">
                    <div></div>
                    <div className="input-group search-size" style={{ maxWidth: '300px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                        <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>

                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${activeTab === 'processed' ? 'active' : ''}`} 
                            onClick={() => {
                                setActiveTab('processed');
                                changeTab('processed');
                            }}>
                            Notas Processadas
                        </button>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${activeTab === 'summary' ? 'active' : ''}`} 
                            onClick={() => {
                                setActiveTab('summary');
                                changeTab('summary');
                            }}>
                            Manifestação de Notas
                        </button>
                    </li>
                </ul>

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : invoices.length > 0 ? (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('number')} className={`sortable-column d-none d-xl-table-cell`} style={{ width: '100px' }}>{activeTab === 'processed'? 'Número': 'Número'} {getSortIcon('number')}</th>
                                    <th onClick={() => handleSort('supplier.legalName')} className='sortable-column'>Fornecedor {getSortIcon('supplier.legalName')}</th>
                                    <th onClick={() => handleSort('issueDate')} className='sortable-column d-none d-xl-table-cell' style={{ width: '160px' }}>Data de Emissão {getSortIcon('issueDate')}</th>
                                    <th onClick={() => handleSort('invoiceAmount')} className='sortable-column d-none d-lg-table-cell text-end me-20' style={{ width: '110px' }}>Valor R$ {getSortIcon('invoiceAmount')}</th>
                                    <th className='text-end me-20' style={{ width: '110px' }}>Manifestação</th>
                                    <th className='text-end' style={{ width: '100px' }}>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice) => (
                                    <React.Fragment key={invoice.id}>
                                        <tr>
                                            <td className={`d-none d-xl-table-cell`}>{activeTab === 'summary'? 'N/A': invoice.number}</td>
                                            <td className="ellipsis">{(invoice.supplier.name || invoice.supplier.legalName).toUpperCase()}</td>
                                            <td className="d-none d-xl-table-cell">{new Date(invoice.issueDate).toLocaleDateString('pt-BR')}</td>
                                            <td className="d-none d-lg-table-cell text-end me-20">{invoice.invoiceAmount ? invoice.invoiceAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}</td>
                                            <td className='text-end me-20'>
                                                {!invoice.manifestations? (
                                                    <span className='status-nao-enviada'>
                                                        Pendente
                                                        {loadingManifestationId === invoice.id && (
                                                            <FontAwesomeIcon icon={faSpinner} spin />
                                                        )}
                                                    </span>
                                                ) : (
                                                    <>
                                                    <FontAwesomeIcon icon={faEye} title={getManifestationTitle(invoice, 'CIENCIA_DA_EMISSAO')} className={getManifestationClass(invoice, 'CIENCIA_DA_EMISSAO')} />
                                                    <FontAwesomeIcon icon={faCheckCircle} title={getManifestationTitle(invoice, 'CONFIRMACAO_DA_OPERACAO')} className={getManifestationClass(invoice, 'CONFIRMACAO_DA_OPERACAO')} />
                                                    <FontAwesomeIcon icon={faTimesCircle} title={getManifestationTitle(invoice, 'OPERACAO_NAO_REALIZADA')} className={getManifestationClass(invoice, 'OPERACAO_NAO_REALIZADA')} />
                                                    <FontAwesomeIcon icon={faQuestionCircle} title={getManifestationTitle(invoice, 'DESCONHECIMENTO_DA_OPERACAO')} className={getManifestationClass(invoice, 'DESCONHECIMENTO_DA_OPERACAO')} />
                                                    </>
                                                )}
                                            </td>
                                            <td className='text-end'>
                                                <div className="d-inline-block no-wrap">
                                                    <div className="dropdown d-inline">
                                                        <button className="btn btn-sm border-secondary custom-btn dropdown-toggle no-dropdown-icon me-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => handleManifestationWithoutJustification(invoice, 'CIENCIA_DA_EMISSAO')} disabled={getManifestation(invoice, 'CIENCIA_DA_EMISSAO')?.status === 135}>Ciência da operação</button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => handleManifestationWithoutJustification(invoice, 'CONFIRMACAO_DA_OPERACAO')} disabled={getManifestation(invoice, 'CONFIRMACAO_DA_OPERACAO')?.status === 135}>Confirmação da Operação</button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => confirmManifestation(invoice, 'OPERACAO_NAO_REALIZADA')} disabled={getManifestation(invoice, 'OPERACAO_NAO_REALIZADA')?.status === 135}>Operação Não Realizada</button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => confirmManifestation(invoice, 'DESCONHECIMENTO_DA_OPERACAO')} disabled={getManifestation(invoice, 'DESCONHECIMENTO_DA_OPERACAO')?.status === 135}>Desconhecimento da Operação</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <button className="btn btn-sm border-secondary custom-btn me-2" title="Ver Notas Fiscais" onClick={() => toggleRowExpansion(invoice.id)}>
                                                    <FontAwesomeIcon icon={isRowExpanded(invoice.id) ? faChevronUp : faChevronDown} />
                                                </button>
                                             </td>
                                        </tr>
                                        {isRowExpanded(invoice.id) && (
                                        <tr>
                                            <td colSpan={activeTab === "summary"? "7": "6"}>
                                                {invoice.type === 'processed' && (
                                                    <div className="p-3 border rounded bg-light">
                                                        <h6>Informações da Nota</h6>
                                                        <ul>
                                                            <li>Emissão: {new Date(invoice.issueDate).toLocaleDateString('pt-BR')}</li>
                                                        </ul>

                                                        <h6>Itens da Nota</h6>
                                                        <ul>
                                                            {invoice.items && invoice.items.length > 0 ? (
                                                                invoice.items.map((item, index) => (
                                                                    <li key={index}>
                                                                        {item.produto} - {item.quantity} {item.unitMeasurement? item.unitMeasurement.initials: 'N/A'} x {item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li>Sem itens</li>
                                                            )}
                                                        </ul>

                                                        <h6 className="mt-3">Forma de Pagamento</h6>
                                                        <ul>
                                                            {invoice.paymentMethods && invoice.paymentMethods.length > 0 ? (
                                                                invoice.paymentMethods.map((payment, index) => (
                                                                    <li key={index}>
                                                                        {payment.paymentType && `Tipo: ${payment.paymentType}`}
                                                                        {payment.paymentMethod && payment.paymentType && ' - '}
                                                                        {payment.paymentMethod && `Método: ${payment.paymentMethod}`}
                                                                        {payment.amount && (payment.paymentType || payment.paymentMethod) && ' - '}
                                                                        {payment.amount && `Valor: ${payment.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li>Sem métodos de pagamento</li>
                                                            )}
                                                        </ul>

                                                        {invoice.billingInstallments && invoice.billingInstallments.length > 0 && (
                                                            <>
                                                                <h6 className="mt-3">Parcelas</h6>
                                                                <ul>
                                                                    {invoice.billingInstallments.map((installment, index) => (
                                                                        <li key={index}>
                                                                            Parcela {installment.installmentNumber}:<br />
                                                                            {installment.dueDate ? `Vencimento: ${new Date(installment.dueDate).toLocaleDateString('pt-BR')}` : 'Sem data'} - 
                                                                            <span className='ms-1'>{installment.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                                {invoice.type === 'summary' && (
                                                    <div className="p-3 border rounded bg-light">
                                                        <h6>Informações da Nota</h6>
                                                        <ul>
                                                            <li>Fornecedor: {invoice.supplier.name}</li>
                                                            <li>Emissão: {new Date(invoice.issueDate).toLocaleDateString('pt-BR')}</li>
                                                            {/*<li>Chave: {invoice.number}</li>*/}
                                                            <li>Valor: {invoice.invoiceAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</li>
                                                        </ul>
                                                        <p>Manifeste o seu conhecimento desta nota fiscal para que o XML seja disponibilizado mais rapidamente. Assim que você manifestar, a nota será processada e aparecerá automaticamente na guia de Notas Processadas quando disponibilizada pela SEFAZ.</p>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>

                        <div className="simpletize-paginator">
                            <Paginator
                                first={page * rows}
                                rows={rows}
                                totalRecords={totalRecords}
                                rowsPerPageOptions={[10, 20, 50]}
                                onPageChange={onPageChange}
                            />
                        </div>
                    </>
                ) : (
                    <div className="no-data">
                        <p>Ainda não há dados por aqui, ou sua pesquisa não retornou resultados.</p>
                    </div>
                )}

                <ManifestationConfirm
                    show={showManifestationConfirmModal}
                    onHide={() => setShowManifestationConfirmModal(false)}
                    onConfirm={handleManifestation}
                />
            </div>
        </CSSTransition>
    );
}

export default InvoiceReceivedList;
