import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/InfoPopover.css';

function InfoPopover({ content }) {
    const [isOpen, setIsOpen] = useState(false);
    const [popoverClass, setPopoverClass] = useState('info-popover-large');
    const [popoverStyle, setPopoverStyle] = useState({});
    const popoverRef = useRef(null);

    const togglePopover = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            adjustPopoverPosition();
        }
    };

    const closePopover = () => {
        setIsOpen(false);
    };

    const adjustPopoverPosition = useCallback(() => {
        if (popoverRef.current) {
            const popoverWidth = popoverRef.current.offsetWidth;
            const iconPosition = popoverRef.current.getBoundingClientRect();
            const screenWidth = window.innerWidth;

            let newLeft = 0;

            // Ajuste a largura do popover com base no espaço disponível
            if (screenWidth < 750) {
                setPopoverClass('info-popover-small');
            } else if (screenWidth < 1024) {
                setPopoverClass('info-popover-medium1');
            } else if (screenWidth < 1280) {
                setPopoverClass('info-popover-medium2');
            } else if (screenWidth > 1650) {
                setPopoverClass('info-popover-large');
            }

            // Ajuste da posição horizontal
            if (iconPosition.left + popoverWidth > screenWidth) {
                newLeft = screenWidth - popoverWidth - iconPosition.left - 10;
            }
            if (iconPosition.left + newLeft < 0) {
                newLeft = -iconPosition.left + 10;
            }

            setPopoverStyle({
                left: newLeft + 'px',
            });
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            adjustPopoverPosition();
        };

        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                closePopover();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, [adjustPopoverPosition]);

    return (
        <div className="info-popover-container" ref={popoverRef}>
            <FontAwesomeIcon
                icon={faInfoCircle}
                className="info-icon"
                onClick={togglePopover}
            />
            {isOpen && (
                <div
                    className={`info-popover-content ${popoverClass}`}
                    style={popoverStyle}
                >
                    {content}
                </div>
            )}
        </div>
    );
}

export default InfoPopover;
