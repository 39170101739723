import React, {useContext} from 'react';
import {AuthContext} from './AuthContext';
import '../assets/styles/CompanyName.css';

function CompanyName() {
    const { auth } = useContext(AuthContext);

    return (
        <div className="company-name">
        {auth.companyName}
        </div>
    );
}

export default CompanyName;
