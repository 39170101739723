import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '0.25rem',
    borderColor: state.isFocused ? '#80bdff' : '#ced4da',
    boxShadow: state.isFocused ? '0 0 0 0.1rem rgba(0,123,255,.25)' : 'none',
    minHeight: '35px',
    height: '35px',
    '&:hover': {
      borderColor: state.isFocused ? '#80bdff' : '#ced4da',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.75rem',
    height: '35px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '35px',
  }),
};

const StaticSelect = ({ options, value, onChange, placeholder }) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      noOptionsMessage={() => 'Nenhuma opção disponível'}
      loadingMessage={() => 'Carregando...'}
      styles={customStyles}
      classNamePrefix="react-select"
    />
  );
};

export default StaticSelect;
