import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/App.css';
import '../assets/styles/Invoice.css';
import '../assets/styles/InvoicePayment.css';
import '../assets/styles/InvoiceBilling.css';
import '../assets/styles/InvoiceStatus.css';
import ReactInputMask from 'react-input-mask';
import { CSSTransition } from 'react-transition-group';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { isValid, parse, format } from 'date-fns';
import { isUndefined } from 'lodash';
import Decimal from 'decimal.js';
import AlertModal from './AlertModal';
const formatStatus = (status) => {
    const statusEnumMap = {
        "NAO_ENVIADA": { label: "Não Enviada", className: "status-nao-enviada" },
        "RECEBIDA": { label: "Recebida", className: "status-recebida" },
        "NFE_AUTORIZADA": { label: "NFe Autorizada", className: "status-autorizada" },
        "PROCESSADA_COM_ERRO": { label: "Processada com Erro", className: "status-com-erro" },
        "CANCELADA": { label: "Cancelada", className: "status-cancelada" }
    };

    return statusEnumMap[status] || { label: "", className: "" };
};

// Registrar o local em português
registerLocale('pt-BR', ptBR);

function InvoiceEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const { auth } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        natureOperationType: '',
        natureOperation: '',
        status: '',
        destinationOperation: '',
        consumerPresentIndicator: '',
        deliveryMode: '',
        carrier: '',
        customer: {
            id: null,
            endConsumer: false,
            simplesNacional: false,
            icmsTaxPayerIndicator: ''
        },
        icmsTotalValue: '',
        icmsTotalValueSt: '',
        ipiTotalValue: '',
        pisTotalValue: '',
        cofinsTotalValue: '',
        totalPovertyAlleviationFund: '',
        totalPovertyAlleviationFundSt: '',
        totalItemsValue: '',
        totalDiscountValue: '',
        icmsTaxExemptValue: '',
        totalInvoiceValue: '',
        totalOtherIncidentalExpenses: '',
        totalDeliveryValue: '',
        totalInsuranceValue: '',
        invoiceCarrier: {
            id: null,
            vehiclePlate: '',
            vehicleProvince: '',
            rntc: '',
            ferry: '',
            wagon: ''
        },
        invoicePayment: {
            id: null,
            invoicePaymentsDetail: [],
            paymentChange: ''
        },
        invoiceItems: [],
        invoiceBillings: []
    });
    const [formErrors, setFormErrors] = useState({});
    const [showCustomNatureOperation, setShowCustomNatureOperation] = useState(false);
    const [customNatureOperation/*, setCustomNatureOperation*/] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [showInfoErrorModal, setShowInfoErrorModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { invoice: initialInvoice, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [invoice, setInvoice] = useState(initialInvoice || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);
    const paymentTypes = {
        vista: [
            { value: '', label: 'Selecione' },
            { value: 'DINHEIRO', label: 'Dinheiro' },
            { value: 'CARTAO_CREDITO', label: 'Cartão de Crédito' },
            { value: 'CARTAO_DEBITO', label: 'Cartão de Débito' },
            { value: 'CARTAO_LOJA', label: 'Cartão da Loja (Private Label)' },
            { value: 'VALE_ALIMENTACAO', label: 'Vale Alimentação' },
            { value: 'VALE_REFEICAO', label: 'Vale Refeição' },
            { value: 'VALE_PRESENTE', label: 'Vale Presente' },
            { value: 'VALE_COMBUSTIVEL', label: 'Vale Combustível' },
            { value: 'DEPOSITO_BANCARIO', label: 'Depósito Bancário' },
            { value: 'PIX_DINAMICO', label: 'Pagamento Instantâneo (PIX) - Dinâmico' },
            { value: 'TRANSFERENCIA_BANCARIA', label: 'Transferência Bancária' },
            { value: 'FIDELIDADE_CASHBACK', label: 'Programa de fidelidade (Cashback)' },
            { value: 'PIX_ESTATICO', label: 'Pagamento Instantâneo (PIX) - Estático' },
            { value: 'CREDITO_EM_LOJA', label: 'Crédito em Loja' },
            { value: 'PAGAMENTO_ELETRONICO_NAO_INFORMADO', label: 'Pagamento Eletrônico não Informado' },
            { value: 'SEM_PAGAMENTO', label: 'Sem pagamento' },
            { value: 'OUTRO', label: 'Outro' }
        ],
        prazo: [
            { value: '', label: 'Selecione' },
            { value: 'CHEQUE', label: 'Cheque' },
            { value: 'DUPLICATA_MERCANTIL', label: 'Duplicata Mercantil' },
            { value: 'BOLETO_BANCARIO', label: 'Boleto Bancário' },
            { value: 'OUTRO', label: 'Outro' }
        ]
    };
    const csosnEnumMap = {
        "101": "TRIBUTADA_COM_PERMISSAO_CREDITO",
        "102": "TRIBUTADA_SEM_PERMISSAO_CREDITO",
        "103": "ISENCAO_ICMS_FAIXA_RECEITA_BRUTA",
        "201": "TRIBUTADA_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA",
        "202": "TRIBUTADA_SIMPLES_NACIONAL_SEM_PERMISSAO_DE_CREDITO_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA",
        "203": "TRIBUTADA_SIMPLES_NACIONAL_PARA_FAIXA_RECEITA_BRUTA_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA",
        "300": "IMUNE",
        "400": "NAO_TRIBUTADA",
        "500": "ICMS_COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA_SUBSIDIO_OU_POR_ANTECIPACAO",
        "900": "OUTROS"
    };
    const originEnumMap = {
        "0": "NACIONAL",
        "1": "ESTRANGEIRA_IMPORTACAO_DIRETA",
        "2": "ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO",
        "3": "NACIONAL_MERCADORIA_OU_BEM_CONTEUDO_IMPORTACAO_SUPERIOR_40_P",
        "4": "NACIONAL_PRODUCAO_EM_CONFORMIDADE_COM_PROCESSOS_PRODUTIVOS_BASICOS",
        "5": "NACIONAL_MERCADORIA_OU_BEM_CONTEUDO_IMPORTACAO_INFERIOR_40_P",
        "6": "ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_SIMILAR_NACIONAL_CONSTANTE_EM_LISTA_CAMEX",
        "7": "ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO_SEM_SIMILAR_NACIONAL_CONSTANTE_EM_LISTA_CAMEX",
        "8": "NACIONAL_MERCADORIA_OU_BEM_COM_CONTEUDO_IMPORTACAO_SUPERIOR_70_P"
    };
    const modalitySTEnumMap = {
        "0": "PRECO_TABELADO",
        "1": "LISTA_NEGATIVA",
        "2": "LISTA_POSITIVA",
        "3": "LISTA_NEUTRA",
        "4": "MARGEM_VALOR_AGREGADO",
        "5": "PAUTA",
        "6": "VALOR_OPERACAO"
    };
    const situationEnumMap = {
        "01": "OPERACAO_TRIBUTAVEL_CUMULATIVO_NAO_CUMULATIVO",
        "02": "OPERACAO_TRIBUTAVEL_ALIQUOTA_DIFERENCIADA",
        "03": "OPERACAO_TRIBUTAVEL_QUANTIDADE_VENDIDA_POR_ALIQUOTA_POR_UNIDADE_PRODUTO",
        "04": "OPERACAO_TRIBUTAVEL_MONOFASICA_ALIQUOTA_ZERO",
        "05": "OPERACAO_TRIBUTAVEL_SUBSTITUICAO_TRIBUTARIA",
        "06": "OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO",
        "07": "OPERACAO_ISENTA_CONTRIBUICAO",
        "08": "OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO",
        "09": "OPERACAO_COM_SUSPENSAO_CONTRIBUICAO",
        "49": "OUTRAS_OPERACOES_SAIDA",
        "50": "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO",
        "51": "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO",
        "52": "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO",
        "53": "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADA_E_NAO_TRIBUTADA_MERCADO_INTERNO",
        "54": "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NO_MERCADO_INTERNO_EXPORTACAO",
        "55": "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_NAO_TRIBUTADA_NO_MERCADO_INTERNO_EXPORTACAO",
        "56": "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
        "60": "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO",
        "61": "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO",
        "62": "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO",
        "63": "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO",
        "64": "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADA_MERCADO_INTERNO_EXPORTACAO",
        "65": "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
        "66": "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
        "67": "CREDITO_PRESUMIDO_OUTRAS_OPERACOES",
        "70": "OPERACAO_AQUISICAO_SEM_DIREITO_CREDITO",
        "71": "OPERACAO_AQUISICAO_COM_ISENCAO",
        "72": "OPERACAO_AQUISICAO_COM_SUSPENSAO",
        "73": "OPERACAO_AQUISICAO_ALIQUOTA_ZERO",
        "74": "OPERACAO_AQUISICAO_SEM_INCIDENCIA_CONTRIBUICAO",
        "75": "OPERACAO_AQUISICAO_POR_SUBSTITUICAO_TRIBUTARIA",
        "98": "OUTRAS_OPERACOES_ENTRADA",
        "99": "OUTRAS_OPERACOES"
    };

    const initializeForm = useCallback((invoice) => {
        const natureOperationType = invoice.natureOperationType || 'VENDA_CONSUMIDOR_FINAL';
        const natureOperation = invoice.natureOperation || 'Venda para consumidor final';
        setInvoice(invoice);

        setFormData({
            natureOperationType: natureOperationType,
            natureOperation: natureOperation,
            status: invoice.status || '',
            consumerPresentIndicator: invoice.consumerPresentIndicator || '',
            customer: {
                id: invoice.customer.id || '',
                endConsumer: invoice.customer.endConsumer || false,
                simplesNacional: invoice.customer.simplesNacional || false,
                icmsTaxPayerIndicator: invoice.customer.icmsTaxPayerIndicator || ''
            },
            destinationOperation: invoice.destinationOperation || '',
            deliveryMode: invoice.deliveryMode || 'SEM_OCORRENCIA_TRANSPORTE',
            carrier: invoice.carrier?.id || '',
            icmsTotalValue: invoice.icmsTotalValue || '',
            icmsTotalValueSt: invoice.icmsTotalValueSt || '',
            ipiTotalValue: invoice.ipiTotalValue || '',
            pisTotalValue: invoice.pisTotalValue || '',
            cofinsTotalValue: invoice.cofinsTotalValue || '',
            totalPovertyAlleviationFund: invoice.totalPovertyAlleviationFund || '',
            totalPovertyAlleviationFundSt: invoice.totalPovertyAlleviationFundSt || '',
            totalItemsValue: invoice.totalItemsValue || '',
            totalDiscountValue: invoice.totalDiscountValue || '',
            icmsTaxExemptValue: invoice.icmsTaxExemptValue || '',
            totalInvoiceValue: invoice.totalInvoiceValue || '',
            totalOtherIncidentalExpenses: invoice.totalOtherIncidentalExpenses? invoice.totalOtherIncidentalExpenses.toFixed(2): '',
            totalDeliveryValue: invoice.totalDeliveryValue? invoice.totalDeliveryValue.toFixed(2): '',
            totalInsuranceValue: invoice.totalInsuranceValue? invoice.totalInsuranceValue.toFixed(2): '',
            additionalInformation: invoice.additionalInformation || '',
            invoiceCarrier: {
                id: invoice?.invoiceCarrier?.id || null,
                vehiclePlate: invoice?.invoiceCarrier?.vehiclePlate || '',
                vehicleProvince: invoice?.invoiceCarrier?.vehicleProvince || '',
                rntc: invoice?.invoiceCarrier?.rntc || '',
                ferry: invoice?.invoiceCarrier?.ferry || '',
                wagon: invoice?.invoiceCarrier?.wagon || ''
            },
            invoicePayment: {
                id: invoice?.invoicePayment?.id || null,
                paymentChange: invoice?.invoicePayment?.paymentChange || '',
                invoicePaymentsDetail: (invoice.invoicePayment.invoicePaymentsDetail || []).map(invoicePaymentsDetail => ({
                    ...invoicePaymentsDetail,
                    paymentAmount: invoicePaymentsDetail.paymentAmount? invoicePaymentsDetail.paymentAmount.toFixed(2): '',
                    id: invoicePaymentsDetail.id || null
                })),
            },
            invoiceItems: (invoice.invoiceItems || []).map(invoiceItem => ({
                ...invoiceItem,
                id: invoiceItem.id || null,
                item: invoiceItem.item || '',
                unitMeasurement: invoiceItem.item.unitMeasurement || '',
                commercialQuantity: invoiceItem.commercialQuantity? invoiceItem.commercialQuantity.toFixed(invoiceItem.item.unitMeasurement.decimals): '',
                commercialUnitValue: invoiceItem.commercialUnitValue || '',
                discountValue: invoiceItem.discountValue? invoiceItem.discountValue.toFixed(2) : '',
                totalAmount: invoiceItem.totalAmount? invoiceItem.totalAmount.toFixed(2) : '',
                code: invoiceItem.code || '',
                description: invoiceItem.description || '',
                commercialUnit: invoiceItem.commercialUnit || '',
                commercialEANCode: invoiceItem.commercialEANCode || '',
                taxableEANCode: invoiceItem.taxableEANCode || '',
                taxableUnit: invoiceItem.taxableUnit || '',
                taxableQuantity: invoiceItem.taxableQuantity? invoiceItem.taxableQuantity.toFixed(invoiceItem.item.unitMeasurement.decimals): '',
                taxableUnitValue: invoiceItem.taxableUnitValue || '',
                deliveryValue: invoiceItem.deliveryValue || '',
                insuranceValue: invoiceItem.insuranceValue || '',
                otherIncidentalExpenses: invoiceItem.otherIncidentalExpenses || '',
                additionalInformation: invoiceItem.additionalInformation || '',
                csosn: invoiceItem.csosn || '',
                icmsOrigin: invoiceItem.icmsOrigin || '',
                icmsCreditPercentage: invoiceItem.icmsCreditPercentage || '',
                icmsStModality: invoiceItem.icmsStModality || '',
                icmsStAliquota: invoiceItem.icmsStAliquota || '',
                icmsStBaseReduction: invoiceItem.icmsStBaseReduction || '',
                icmsStMarginValueAdded: invoiceItem.icmsStMarginValueAdded || '',
                fcpStPercentage: invoiceItem.fcpStPercentage || '',
                icmsEffectiveBaseReduction: invoiceItem.icmsEffectiveBaseReduction || '',
                fcpRetainedPercentage: invoiceItem.fcpRetainedPercentage || '',
                icmsEffectivePercentage: invoiceItem.icmsEffectivePercentage || '',
                icmsStRetainedPercentage: invoiceItem.icmsStRetainedPercentage || '',
                pisAliquota: invoiceItem.pisAliquota || '',
                pisSituation: invoiceItem.pisSituation || '',
                cofinsAliquota: invoiceItem.cofinsAliquota || '',
                cofinsSituation: invoiceItem.cofinsSituation || '',
                ipiAliquota: invoiceItem.ipiAliquota || '',
                ipiCode: invoiceItem.ipiCode || '',
                cnpjProdutor: invoiceItem.cnpjProdutor || '',
                codigoSelo: invoiceItem.codigoSelo || '',
                quantidadeSelo: invoiceItem.quantidadeSelo || '',
                icmsStCalculationBase: invoiceItem.icmsStCalculationBase || '',
                fcpCalculationBase: invoiceItem.fcpCalculationBase || '',
                icmsCalculationBase: invoiceItem.icmsCalculationBase || '',
                icmsValue: invoiceItem.icmsValue || '',
                pisCalculationBase: invoiceItem.pisCalculationBase || '',
                pisValue: invoiceItem.pisValue || '',
                cofinsCalculationBase: invoiceItem.cofinsCalculationBase || '',
                cofinsValue: invoiceItem.cofinsValue || '',
                ipiCalculationBase: invoiceItem.ipiCalculationBase || '',
                ipiValue: invoiceItem.ipiValue || '',
                codigoBeneficioFiscal: invoiceItem.codigoBeneficioFiscal || '',
                cfop: invoiceItem.cfop || '',
                ncm: invoiceItem.ncm || '',
                cest: invoiceItem.cest || ''
            })),
            invoiceBillings: (invoice.invoiceBillings || []).map(invoiceBilling => ({
                ...invoiceBilling,
                id: invoiceBilling.id || null,
                installmentAmount: invoiceBilling.installmentAmount? invoiceBilling.installmentAmount.toFixed(2): ''
            }))
        });

        setSelectedCustomer({
            value: invoice.customer.id,
            label: invoice.customer.legalName,
            addresses: invoice.customer.addresses,
            endConsumer: invoice.customer.endConsumer,
            simplesNacional: invoice.customer.simplesNacional,
            icmsTaxPayerIndicator: invoice.customer.icmsTaxPayerIndicator
        });

        setSelectedCarrier({
            value: invoice.carrier?.id || '',
            label: invoice.carrier?.legalName || ''
        });
    }, []);

    const loadInvoice = useCallback(async () => {
        try {
            const response = await axios.get(`/api/invoices/outgoing/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar nota fiscal:', error);
            if (error.response && error.response.status === 404) {
                navigate('/invoices/outgoing', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, initializeForm, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadInvoice();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setFormData((prevFormData) => ({
                ...prevFormData,
                natureOperationType: 'VENDA_CONSUMIDOR_FINAL',
                natureOperation: 'Venda para consumidor final',
                status: '',
                consumerPresentIndicator: '',
                deliveryMode: 'SEM_OCORRENCIA_TRANSPORTE',
                destinationOperation: '',
                customer: {
                    id: '',
                    endConsumer: false,
                    simplesNacional: false,
                    icmsTaxPayerIndicator: ''
                },
                carrier: '',
                icmsTotalValue: '',
                icmsTotalValueSt: '',
                ipiTotalValue: '',
                pisTotalValue: '',
                cofinsTotalValue: '',
                totalPovertyAlleviationFund: '',
                totalPovertyAlleviationFundSt: '',
                totalItemsValue: '',
                totalDiscountValue: '',
                icmsTaxExemptValue: '',
                totalInvoiceValue: '',
                totalOtherIncidentalExpenses: '',
                totalDeliveryValue: '',
                totalInsuranceValue: '',
                additionalInformation: '',
                invoiceCarrier: {
                    id: null,
                    vehiclePlate: '',
                    vehicleProvince: '',
                    rntc: '',
                    ferry: '',
                    wagon: ''
                },
                invoicePayment: {
                    id: null,
                    invoicePaymentsDetail: [
                        {
                            id: Date.now(),
                            paymentMethod: 'A_VISTA',
                            paymentType: '',
                            paymentDate: '',
                            paymentAmount: ''
                        }
                    ],
                    paymentChange: ''
                },
                invoiceItems: [
                    {
                        id: Date.now(),
                        item: '',
                        commercialQuantity: '',
                        commercialUnitValue: '',
                        discountValue: '',
                        totalAmount: '',
                        code: '',
                        description: '',
                        commercialUnit: '',
                        commercialEANCode: '',
                        taxableEANCode: '',
                        taxableUnit: '',
                        taxableQuantity: '',
                        taxableUnitValue: '',
                        deliveryValue: '',
                        insuranceValue: '',
                        otherIncidentalExpenses: '',
                        additionalInformation: '',
                        csosn: '',
                        icmsOrigin: '',
                        icmsCreditPercentage: '',
                        icmsStModality: '',
                        icmsStAliquota: '',
                        icmsStBaseReduction: '',
                        icmsStMarginValueAdded: '',
                        fcpStPercentage: '',
                        icmsEffectiveBaseReduction: '',
                        fcpRetainedPercentage: '',
                        icmsEffectivePercentage: '',
                        icmsStRetainedPercentage: '',
                        pisAliquota: '',
                        pisSituation: '',
                        cofinsAliquota: '',
                        cofinsSituation: '',
                        ipiAliquota: '',
                        ipiCode: '',
                        cnpjProdutor: '',
                        codigoSelo: '',
                        quantidadeSelo: '',
                        icmsStCalculationBase: '',
                        fcpCalculationBase: '',
                        icmsCalculationBase: '',
                        icmsValue: '',
                        pisCalculationBase: '',
                        pisValue: '',
                        cofinsCalculationBase: '',
                        cofinsValue: '',
                        ipiCalculationBase: '',
                        ipiValue: '',
                        codigoBeneficioFiscal: '',
                        cfop: '',
                        ncm: '',
                        cest: ''
                    }
                ],
                invoiceBillings: []
            }));
            setShowForm(true);
        }
    }, [axiosConfigured, id, loadInvoice]);

    useEffect(() => {
        setInProp(true);
    }, []);

    // Função para calcular os impostos
    const calculateTaxes = useCallback((invoiceItems, totalDeliveryValue, totalInsuranceValue, totalOtherIncidentalExpenses, destinationOperation) => {
        if (invoice.status !== 'NFE_AUTORIZADA' && invoice.status !== 'CANCELADA' && invoice.status !== 'RECEBIDA') {
            // Função auxiliar para garantir que os valores sejam numéricos ou zero
            const safeDecimal = (value) => {
                if (typeof value === 'string') {
                    const parsedValue = parseFloat(value.replace(",", "."));
                    return isNaN(parsedValue) ? new Decimal(0) : new Decimal(parsedValue);
                }
                return value !== undefined && value !== null && !isNaN(value) ? new Decimal(value) : new Decimal(0);
            };
        
            // Inicializando os totais e bases de cálculo com Decimal
            let icmsTotal = new Decimal(0);
            let icmsStTotal = new Decimal(0);
            let ipiTotal = new Decimal(0);
            let pisTotal = new Decimal(0);
            let cofinsTotal = new Decimal(0);
            let fcpTotal = new Decimal(0);
            let fcpStTotal = new Decimal(0);
            let totalItemsValue = new Decimal(0);
            let totalDiscountValue = new Decimal(0);
            let icmsTaxExemptValue = new Decimal(0);
            let totalDelivery = safeDecimal(totalDeliveryValue);
            let totalInsurance = safeDecimal(totalInsuranceValue);
            let totalOtherExpenses = safeDecimal(totalOtherIncidentalExpenses);
        
            // Iterando pelos itens da nota para calcular os valores
            if (invoiceItems.length > 0 && selectedCustomer && destinationOperation) {
                const isFinalConsumer = selectedCustomer?.endConsumer;
                const isSimpleNational = selectedCustomer?.simplesNacional;
                const isInterstate = destinationOperation === "OPERACAO_INTERESTADUAL";
        
                invoiceItems.forEach(invoiceItem => {
                    if (invoiceItem.item) {
                        let icmsItemBase = safeDecimal(invoiceItem.commercialQuantity).times(safeDecimal(invoiceItem.commercialUnitValue));
        
                        // Cálculo de ICMS Crédito (somente se icmsCreditPercentage estiver configurado)
                        if (invoiceItem.icmsCreditPercentage) {
                            let icmsCreditPercentage = safeDecimal(invoiceItem.icmsCreditPercentage).dividedBy(100);
                            let icmsCreditValue = icmsItemBase.times(icmsCreditPercentage).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
                            invoiceItem.icmsCreditValue = icmsCreditValue.toNumber();
                            invoiceItem.icmsCalculationBase = 0;
                        } else {
                            invoiceItem.icmsCreditValue = 0;
                        }
        
                        let icmsStItemBase = new Decimal(0);
        
                        // Cálculo do ICMS ST (considerando apenas se icmsStAliquota e icmsStModality estiverem presentes)
                        if (invoiceItem.icmsStAliquota && invoiceItem.icmsStModality) {
                            switch (invoiceItem.icmsStModality) {
                                case "PRECO_TABELADO":
                                case "PAUTA":
                                    icmsStItemBase = icmsItemBase;
                                    break;
                                case "LISTA_NEGATIVA":
                                case "LISTA_POSITIVA":
                                case "LISTA_NEUTRA":
                                    let icmsStBaseReduction = safeDecimal(invoiceItem.icmsStBaseReduction).dividedBy(100);
                                    icmsStItemBase = icmsItemBase.times(new Decimal(1).minus(icmsStBaseReduction));
                                    break;
                                case "MARGEM_VALOR_AGREGADO":
                                    let icmsStMarginValueAdded = safeDecimal(invoiceItem.icmsStMarginValueAdded).dividedBy(100);
                                    icmsStItemBase = icmsItemBase.times(new Decimal(1).plus(icmsStMarginValueAdded));
                                    break;
                                case "VALOR_OPERACAO":
                                    icmsStItemBase = icmsItemBase;
                                    break;
                                default:
                                    console.warn(`Modalidade de ICMS ST não tratada: ${invoiceItem.icmsStModality}`);
                                    break;
                            }
        
                            let icmsStAliquota = safeDecimal(invoiceItem.icmsStAliquota).dividedBy(100);
                            let icmsStValue = icmsStItemBase.times(icmsStAliquota).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
        
                            if (icmsStValue.greaterThan(0)) {
                                invoiceItem.icmsStCalculationBase = icmsStItemBase.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                                icmsStTotal = icmsStTotal.plus(icmsStValue);
                            } else {
                                invoiceItem.icmsStCalculationBase = 0;
                            }
                        }
        
                        // Cálculo do FCP ST (apenas se fcpStPercentage estiver configurado)
                        if (isInterstate && isFinalConsumer && invoiceItem.fcpStPercentage) {
                            let fcpStPercentage = safeDecimal(invoiceItem.fcpStPercentage).dividedBy(100);
                            let fcpStValue = icmsStItemBase.times(fcpStPercentage).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
                            
                            if (fcpStValue.greaterThan(0)) {
                                invoiceItem.fcpStValue = fcpStValue.toNumber();
                                fcpStTotal = fcpStTotal.plus(fcpStValue);
                            } else {
                                invoiceItem.fcpStValue = 0;
                            }
                        } else {
                            invoiceItem.fcpStValue = 0;
                        }
        
                        // Cálculo do IPI
                        if (!isFinalConsumer && !isSimpleNational && invoiceItem.ipiAliquota) {
                            let ipiAliquota = safeDecimal(invoiceItem.ipiAliquota).dividedBy(100);
                            let ipiValue = icmsItemBase.times(ipiAliquota).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
        
                            if (ipiValue.greaterThan(0)) {
                                invoiceItem.ipiCalculationBase = icmsItemBase.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                                ipiTotal = ipiTotal.plus(ipiValue);
                            } else {
                                invoiceItem.ipiCalculationBase = 0;
                            }
                        } else {
                            invoiceItem.ipiCalculationBase = 0;
                        }
        
                        // Cálculo do PIS
                        if (!isSimpleNational && invoiceItem.pisAliquota) {
                            let pisAliquota = safeDecimal(invoiceItem.pisAliquota).dividedBy(100);
                            let pisValue = icmsItemBase.times(pisAliquota).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
        
                            if (pisValue.greaterThan(0)) {
                                invoiceItem.pisCalculationBase = icmsItemBase.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                                pisTotal = pisTotal.plus(pisValue);
                            } else {
                                invoiceItem.pisCalculationBase = 0;
                            }
                        } else {
                            invoiceItem.pisCalculationBase = 0;
                        }
        
                        // Cálculo do COFINS
                        if (!isSimpleNational && invoiceItem.cofinsAliquota) {
                            let cofinsAliquota = safeDecimal(invoiceItem.cofinsAliquota).dividedBy(100);
                            let cofinsValue = icmsItemBase.times(cofinsAliquota).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
        
                            if (cofinsValue.greaterThan(0)) {
                                invoiceItem.cofinsCalculationBase = icmsItemBase.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                                cofinsTotal = cofinsTotal.plus(cofinsValue);
                            } else {
                                invoiceItem.cofinsCalculationBase = 0;
                            }
                        } else {
                            invoiceItem.cofinsCalculationBase = 0;
                        }
        
                        // Cálculo do FCP
                        /*if (isInterstate && isFinalConsumer && invoiceItem.fcpStPercentage) {
                            let fcpPercentage = safeDecimal(invoiceItem.fcpStPercentage).dividedBy(100);
                            let fcpValue = icmsItemBase.times(fcpPercentage).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
        
                            if (fcpValue.greaterThan(0)) {
                                invoiceItem.fcpCalculationBase = icmsItemBase.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                                fcpTotal = fcpTotal.plus(fcpValue);
                            } else {
                                invoiceItem.fcpCalculationBase = 0;
                            }
                        } else {
                            invoiceItem.fcpCalculationBase = 0;
                        }*/
                        invoiceItem.fcpCalculationBase = 0;
                        fcpTotal = 0;
        
                        let discountValue = safeDecimal(invoiceItem.discountValue);
        
                        totalItemsValue = totalItemsValue.plus(icmsItemBase);
                        totalDiscountValue = totalDiscountValue.plus(discountValue);
        
                        // Definindo isenção de ICMS conforme o CSOSN
                        const isImmune = ["TRIBUTADA_SEM_PERMISSAO_CREDITO", "ISENCAO_ICMS_FAIXA_RECEITA_BRUTA", "IMUNE", "NAO_TRIBUTADA"].includes(invoiceItem.csosn);
                        if (isImmune) {
                            icmsTaxExemptValue = icmsTaxExemptValue.plus(icmsItemBase);
                        }
                    }
                });
            }
        
            let totalInvoiceValue = totalItemsValue.minus(totalDiscountValue)
                .plus(icmsTotal)
                .plus(icmsStTotal)
                .plus(ipiTotal)
                //.plus(pisTotal)
                //.plus(cofinsTotal)
                .plus(fcpTotal)
                .plus(fcpStTotal)
                .plus(totalDelivery)
                .plus(totalInsurance)
                .plus(totalOtherExpenses);
        
            // Arredondamento final dos totais
            setFormData((prevData) => ({
                ...prevData,
                icmsTotalValue: icmsTotal.toFixed(2),
                icmsTotalValueSt: icmsStTotal.toFixed(2),
                ipiTotalValue: ipiTotal.toFixed(2),
                pisTotalValue: pisTotal.toFixed(2),
                cofinsTotalValue: cofinsTotal.toFixed(2),
                totalPovertyAlleviationFund: fcpTotal.toFixed(2),
                totalPovertyAlleviationFundSt: fcpStTotal.toFixed(2),
                totalItemsValue: totalItemsValue.toFixed(2),
                totalDiscountValue: totalDiscountValue.toFixed(2),
                icmsTaxExemptValue: icmsTaxExemptValue.toFixed(2),
                totalInvoiceValue: totalInvoiceValue.toFixed(2)
            }));            
        }
    }, [selectedCustomer, invoice.status]);    

    useEffect(() => {
        calculateTaxes(formData.invoiceItems, formData.totalDeliveryValue, formData.totalInsuranceValue, formData.totalOtherIncidentalExpenses, formData.destinationOperation);
    }, [calculateTaxes, formData.invoiceItems, formData.totalDeliveryValue, formData.totalInsuranceValue, formData.totalOtherIncidentalExpenses, formData.destinationOperation]);

    const extractCodeFromDescription = (description) => {
        if (description) {
            const codeMatch = description.match(/^\d+/);
            return codeMatch ? codeMatch[0] : '';
        }
        return '';
    };

    function removeFormatting(value) {
        if (!value) {
            return '';
        }
        return value.replace(/[^a-zA-Z0-9]/g, '');
    }

    const handleShowInfoErrorModal = (protocol) => {
        setShowInfoErrorModal(protocol);
    };

    const handleCurrencyChange = (value, name) => {
        setFormData((prevData) => {
            const isChanged = prevData[name] !== value;
            if (isChanged) {
                setIsFormChanged(true);
            }

            const updatedForm = {
                ...prevData,
                [name]: value
            };

            return updatedForm;
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'natureOperationType') {
            setShowCustomNatureOperation(false);
            const selectedOptionLabel = e.target.options[e.target.selectedIndex].text;
            setFormData((prevData) => ({
                ...prevData,
                natureOperationType: value,
                natureOperation: selectedOptionLabel
            }));
        } else if (name === 'deliveryMode') {
            if (value === 'CONTRATACAO_POR_CONTA_DO_REMETENTE' || value === 'CONTRATACAO_POR_CONTA_DO_DESTINATARIO' || value === 'CONTRATACAO_POR_CONTA_DE_TERCEIROS') {
                if (formData.deliveryMode !== 'CONTRATACAO_POR_CONTA_DO_REMETENTE' && formData.deliveryMode !== 'CONTRATACAO_POR_CONTA_DO_DESTINATARIO' && formData.deliveryMode !== 'CONTRATACAO_POR_CONTA_DE_TERCEIROS') {
                    formData.invoiceCarrier.vehiclePlate = '';
                    formData.invoiceCarrier.vehicleProvince = '';
                }
            }

            if (value === 'PROPRIO_POR_CONTA_DO_REMETENTE') {
                setSelectedCarrier(null);
                setFormData((prevData) => ({
                    ...prevData,
                    deliveryMode: value,
                    carrier: '',
                    totalDeliveryValue: '',
                    totalInsuranceValue: ''
                }));
            } else if (value === 'PROPRIO_POR_CONTA_DO_DESTINATARIO' || value === 'SEM_OCORRENCIA_TRANSPORTE') {
                setSelectedCarrier(null);
                setFormData((prevData) => ({
                    ...prevData,
                    deliveryMode: value,
                    carrier: '',
                    totalDeliveryValue: '',
                    totalInsuranceValue: '',
                    invoiceCarrier: {
                        id: prevData.invoiceCarrier.id,
                        vehiclePlate: '',
                        vehicleProvince: '',
                        rntc: '',
                        ferry: '',
                        wagon: ''
                    }
                }));
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value
                }));
            }
        } else if (['vehiclePlate', 'vehicleProvince', 'rntc', 'ferry', 'wagon'].includes(name)) {
            setFormData((prevData) => ({
                ...prevData,
                invoiceCarrier: {
                    ...prevData.invoiceCarrier,
                    [name]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

        setIsFormChanged(true);
    };

    const handleDisableCustomer = () => {
        return formData.invoiceItems.some((invoiceItem) => invoiceItem.item.id);
    }

    const handleCustomerChange = (selectedOption) => {
        const selectedCus = selectedOption ? {
            id: selectedOption.value,
            legalName: selectedOption.label,
            addresses: selectedOption.addresses,
            endConsumer: selectedOption.endConsumer,
            simplesNacional: selectedOption.simplesNacional,
            icmsTaxPayerIndicator: selectedOption.icmsTaxPayerIndicator
        } : null;

        setSelectedCustomer(selectedOption);

        setFormData((prevData) => {
            const isChanged = prevData.customer !== selectedCus?.id;
            let destinationOperation = prevData.destinationOperation;

            if (isChanged && selectedCus) {
                const billingAddress = selectedCus.addresses?.find(address => address.billingAddress) || selectedCus.addresses?.[0];

                if (billingAddress) {
                    if (billingAddress.city) {
                        if (auth.companyProvince === billingAddress.city.uf) {
                            destinationOperation = 'OPERACAO_INTERNA';
                        } else {
                            destinationOperation = 'OPERACAO_INTERESTADUAL';
                        }
                    }
                } else {
                    destinationOperation = '';
                }

                setIsFormChanged(true);
            }

            return {
                ...prevData,
                customer: {
                    id: selectedCus?.id || null,
                    endConsumer: selectedCus?.endConsumer || false,
                    simplesNacional: selectedCus?.simplesNacional || false,
                    icmsTaxPayerIndicator: selectedCus?.icmsTaxPayerIndicator
                },
                destinationOperation: destinationOperation
            };
        });
    };

    const handleCarrierChange = (selectedOption) => {
        const selectedCarr = selectedOption ? {
            id: selectedOption.value,
            legalName: selectedOption.label
        } : null;

        setSelectedCarrier(selectedOption);

        setFormData((prevData) => {
            const isChanged = prevData.carrier !== selectedCarr?.id;

            if (isChanged && selectedCarr) {
                setIsFormChanged(true);
            }

            return {
                ...prevData,
                carrier: selectedCarr?.id || null
            };
        });
    };

    const getCustomerUf = () => {
        if (!selectedCustomer || !selectedCustomer.addresses || selectedCustomer.addresses.length === 0) {
            return null;
        }
    
        // Procurar o endereço de cobrança (billingAddress)
        let customerAddress = selectedCustomer.addresses.find(address => address.billingAddress);
    
        // Se não encontrar o endereço de cobrança, pegar o primeiro endereço disponível
        if (!customerAddress) {
            customerAddress = selectedCustomer.addresses[0];
        }

        return customerAddress?.city?.uf || null;
    };

    const getTaxRuleDetail = (index, taxRule) => {
        // Passo 1: Verificar se o cliente está selecionado
        if (!selectedCustomer) {
            setAlert({
                type: 'warning',
                message: 'Selecione o cliente antes de incluir os itens. Precisamos dessa informação para calcular os impostos da nota fiscal.',
            });
            return;
        }

        // Passo 2: Verificar se existe uma regra fiscal associada ao item ou à categoria de venda
        if (isUndefined(taxRule) || !taxRule) {
            setAlert({
                type: 'danger',
                message: 'O item selecionado não possui uma regra fiscal. Atribua uma regra fiscal na categoria de venda ou no próprio item.',
            });
    
            // Limpa a linha do item
            const updatedItems = [...formData.invoiceItems];
            updatedItems[index] = {
                ...updatedItems[index],
                item: '',
                salesCategory: '',
                unitMeasurement: '',
                commercialQuantity: '',
                commercialUnitValue: '',
                discountValue: '',
                totalAmount: '',
            };
    
            setFormData((prevData) => ({
                ...prevData,
                invoiceItems: updatedItems,
            }));
    
            return;
        }
    
        // Passo 3: Buscar a regra fiscal da categoria de venda
        let selectedTaxRule = taxRule;
    
        // Passo 4: Buscar a regra fiscal com base na natureza da operação e no UF do cliente
        let destinationUf = getCustomerUf();

        /*if (formData.destinationOperation === 'OPERACAO_INTERNA') {
            destinationUf = auth.companyProvince
        }*/

        const operationNatureCode = formData.natureOperationType;
    
        let taxRuleDetail = null;

        // Verifica a regra fiscal para o estado específico
        taxRuleDetail = selectedTaxRule.taxRuleDetails.find(
            (detail) =>
                detail.natureOperation === operationNatureCode &&
                detail.province === destinationUf
        );
    
        // Caso não encontre a regra fiscal específica para o estado, verifica para todos os estados
        if (!taxRuleDetail) {
            taxRuleDetail = selectedTaxRule.taxRuleDetails.find(
                (detail) =>
                    detail.natureOperation === operationNatureCode &&
                    detail.province === 'TODOS'
            );
        }
    
        // Se ainda não encontrar, procura pela natureza de operação 'TODAS' e o estado específico
        if (!taxRuleDetail) {
            taxRuleDetail = selectedTaxRule.taxRuleDetails.find(
                (detail) =>
                    detail.natureOperation === 'TODAS' &&
                    detail.province === destinationUf
            );
        }
    
        // Se não encontrar regra fiscal para estado e natureza, busca por todos os estados e todas as naturezas
        if (!taxRuleDetail) {
            taxRuleDetail = selectedTaxRule.taxRuleDetails.find(
                (detail) =>
                    detail.natureOperation === 'TODAS' &&
                    detail.province === 'TODOS'
            );
        }
    
        // Passo 5: Validar se encontrou uma regra fiscal válida
        if (!taxRuleDetail) {
            setAlert({
                type: 'danger',
                message: 'Nenhuma regra fiscal válida foi encontrada para este item e cliente.',
            });
            return;
        }
    
        return taxRuleDetail;
    };

    const getCsosnEnumName = (code) => {
        return csosnEnumMap[code];
    };

    const getOriginEnumName = (code) => {
        return originEnumMap[code]
    };

    const getModalitySTEnumName = (code) => {
        return modalitySTEnumMap[code];
    };

    const getSituationEnumName = (code) => {
        return situationEnumMap[code];
    };

    const handleItemSelectionChange = (index, selectedOption) => {
        const updatedItems = [...formData.invoiceItems];
        const previousItemId = updatedItems[index]?.item?.id;

        if (selectedOption) {
            const newItemId = selectedOption?.value || '';

            if (previousItemId !== newItemId) {
                const taxRule = selectedOption?.taxRule || selectedOption.salesCategory?.taxRule;
                const taxRuleDetail = getTaxRuleDetail(index, taxRule);

                if (!taxRuleDetail) {
                    return;
                }

                updatedItems[index] = {
                    ...updatedItems[index],
                    item: {
                        id: newItemId,
                        description: selectedOption?.label
                    },
                    salesCategory: selectedOption?.salesCategory,
                    unitMeasurement: selectedOption?.unitMeasurement,
                    commercialUnitValue: selectedOption?.currentPrice,
                    commercialQuantity: '',
                    discountValue: '',
                    totalAmount: '',
                    code: updatedItems[index].code,
                    description: '',
                    commercialUnit: selectedOption?.unitMeasurement.initials,
                    commercialEANCode: '',
                    taxableEANCode: '',
                    taxableUnit: selectedOption?.unitMeasurement.initials,
                    taxableQuantity: '',
                    taxableUnitValue: selectedOption?.currentPrice,
                    deliveryValue: '',
                    insuranceValue: '',
                    otherIncidentalExpenses: '',
                    additionalInformation: '',
                    csosn: getCsosnEnumName(extractCodeFromDescription(taxRuleDetail.csosn)),
                    icmsOrigin: getOriginEnumName(extractCodeFromDescription(taxRuleDetail.icmsOrigin)),
                    icmsCreditPercentage: taxRuleDetail.icmsCreditPercentage || '',
                    icmsStModality: taxRuleDetail.icmsStModality? getModalitySTEnumName(extractCodeFromDescription(taxRuleDetail.icmsStModality)): '',
                    icmsStAliquota: taxRuleDetail.icmsStAliquota || '',
                    icmsStBaseReduction: taxRuleDetail.icmsStBaseReduction || '',
                    icmsStMarginValueAdded: taxRuleDetail.icmsStMarginValueAdded || '',
                    fcpStPercentage: taxRuleDetail.fcpStPercentage || '',
                    icmsEffectiveBaseReduction: taxRuleDetail.icmsEffectiveBaseReduction || '',
                    fcpRetainedPercentage: taxRuleDetail.fcpRetainedPercentage || '',
                    icmsEffectivePercentage: taxRuleDetail.icmsEffectivePercentage || '',
                    icmsStRetainedPercentage: taxRuleDetail.icmsStRetainedPercentage || '',
                    pisAliquota: taxRuleDetail.pisAliquota || '',
                    pisSituation: getSituationEnumName(extractCodeFromDescription(taxRuleDetail.pisSituation)),
                    cofinsAliquota: taxRuleDetail.cofinsAliquota || '',
                    cofinsSituation: getSituationEnumName(extractCodeFromDescription(taxRuleDetail.cofinsSituation)),
                    ipiAliquota: taxRuleDetail.ipiAliquota || '',
                    ipiCode: taxRuleDetail.ipiCode || '',
                    cnpjProdutor: taxRuleDetail.cnpjProdutor || '',
                    codigoSelo: taxRuleDetail.codigoSelo || '',
                    quantidadeSelo: taxRuleDetail.quantidadeSelo || '',
                    codigoBeneficioFiscal: taxRuleDetail.codigoBeneficioFiscal || '',
                    cfop: taxRuleDetail.cfop || '',
                    ncm: taxRuleDetail.ncm || '',
                    cest: taxRuleDetail.cest || '',
                    icmsStCalculationBase: '',
                    icmsCalculationBase: '',
                    fcpCalculationBase: '',
                    icmsValue: '',
                    pisCalculationBase: '',
                    pisValue: '',
                    cofinsCalculationBase: '',
                    cofinsValue: '',
                    ipiCalculationBase: '',
                    ipiValue: ''
                };
            }
        } else {
            updatedItems[index] = {
                ...updatedItems[index],
                item: '',
                salesCategory: '',
                unitMeasurement: '',
                commercialQuantity: '',
                commercialUnitValue: '',
                discountValue: '',
                totalAmount: '',
                code: '',
                description: '',
                commercialUnit: '',
                commercialEANCode: '',
                taxableEANCode: '',
                taxableUnit: '',
                taxableQuantity: '',
                taxableUnitValue: '',
                deliveryValue: '',
                insuranceValue: '',
                otherIncidentalExpenses: '',
                additionalInformation: '',
                csosn: '',
                icmsOrigin: '',
                icmsCreditPercentage: '',
                icmsStModality: '',
                icmsStAliquota: '',
                icmsStBaseReduction: '',
                icmsStMarginValueAdded: '',
                fcpStPercentage: '',
                icmsEffectiveBaseReduction: '',
                fcpRetainedPercentage: '',
                icmsEffectivePercentage: '',
                icmsStRetainedPercentage: '',
                pisAliquota: '',
                pisSituation: '',
                cofinsAliquota: '',
                cofinsSituation: '',
                ipiAliquota: '',
                ipiCode: '',
                cnpjProdutor: '',
                codigoSelo: '',
                quantidadeSelo: '',
                icmsCalculationBase: '',
                icmsStCalculationBase: '',
                fcpCalculationBase: '',
                icmsValue: '',
                pisCalculationBase: '',
                pisValue: '',
                cofinsCalculationBase: '',
                cofinsValue: '',
                ipiCalculationBase: '',
                ipiValue: '',
                codigoBeneficioFiscal: '',
                cfop: '',
                ncm: '',
                cest: ''
            };
        }

        setFormData((prevData) => ({
            ...prevData,
            invoiceItems: updatedItems
        }));
        setIsFormChanged(true);
    };

    const handleQuantityChange = (index, e, decimals = 0) => {
        let value = e.target.value;

        value = value.replace(',', '.');

        if (decimals === 0 && value.includes('.')) {
            e.target.value = value.split('.')[0];
            return;
        }

        if (value.includes('.')) {
            const [integerPart, decimalPart] = value.split('.');

            if (decimalPart && decimalPart.length > decimals) {
                value = `${integerPart}.${decimalPart.slice(0, decimals)}`;
                e.target.value = value;
            }
        }

        const regex = new RegExp(`^\\d*(\\.\\d{0,${decimals}})?$`);

        if (regex.test(value) || value === '') {
            handleItemInputChange(index, { target: { name: e.target.name, value } });
        } else {
            e.target.value = '';
            handleItemInputChange(index, { target: { name: e.target.name, value: '' } });
        }
    };

    const handleItemInputChange = (index, nameOrEvent, value = null) => {
        const updatedItems = [...formData.invoiceItems];

        if (typeof nameOrEvent === 'object') {
            const { name, value: eventValue } = nameOrEvent.target;
            updatedItems[index] = {
                ...updatedItems[index],
                [name]: eventValue,
            };
        } else {
            updatedItems[index] = {
                ...updatedItems[index],
                [nameOrEvent]: value,
            };
        }

        const quantity = parseFloat(updatedItems[index].commercialQuantity || 0);
        const unitValue = parseFloat(updatedItems[index].commercialUnitValue || 0);
        let discount = parseFloat(updatedItems[index].discountValue?.replace(',', '.') || 0);

        let totalAmount = (quantity * unitValue) - discount;
        totalAmount = parseFloat((Math.round((totalAmount + Number.EPSILON) * 100) / 100)).toFixed(2)

        if (totalAmount < 0) {
            discount = quantity * unitValue;
            totalAmount = 0;
            updatedItems[index].discountValue = discount.toFixed(2).replace('.', ',');
        }

        updatedItems[index].totalAmount = parseFloat(totalAmount).toFixed(2);

        setFormData((prevData) => ({
            ...prevData,
            invoiceItems: updatedItems
        }));
        setIsFormChanged(true);

        // Chama a função de cálculo de impostos com os itens atualizados
        //calculateTaxes(updatedItems);
    };

    const handlePaymentInputChange = (index, nameOrEvent, value = null) => {
        const updatedPaymentsDetail = [...formData.invoicePayment.invoicePaymentsDetail];
        let updatedBillings = [...formData.invoiceBillings];

        if (typeof nameOrEvent === 'object') {
            const { name, value: eventValue } = nameOrEvent.target;
            updatedPaymentsDetail[index] = {
                ...updatedPaymentsDetail[index],
                [name]: eventValue,
            };
        } else {
            updatedPaymentsDetail[index] = {
                ...updatedPaymentsDetail[index],
                [nameOrEvent]: value,
            };
        }
    
        if (nameOrEvent === 'paymentMethod' || nameOrEvent.target?.name === 'paymentMethod') {
            const newPaymentMethod = nameOrEvent.target ? nameOrEvent.target.value : nameOrEvent;
            const newPaymentType = newPaymentMethod === 'A_PRAZO' ? 'prazo' : 'vista';
            updatedPaymentsDetail[index].paymentType = paymentTypes[newPaymentType][0].value;

            if (newPaymentMethod === 'A_PRAZO') {
                updatedPaymentsDetail[index].paymentDate = '';
                //updatedPaymentsDetail[index].paymentAmount = '0,00';
            }
        }

        //Limpa informações de cobrança/parcelas se não houver forma de pagamento a prazo
        let existPaymentInstallment = updatedPaymentsDetail.some((payment) => payment.paymentMethod === 'A_PRAZO');

        if (!existPaymentInstallment) {
            updatedBillings = [];
        } else {
            if (formData.invoiceBillings.length === 0 && existPaymentInstallment) {
                updatedBillings = [
                    {
                        id: Date.now(),
                        installmentNumber: formData.invoiceBillings.length + 1,
                        installmentAmount: '',
                        installmentDueDate: ''
                    }
                ];
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            invoicePayment: {
                ...prevData.invoicePayment,
                invoicePaymentsDetail: updatedPaymentsDetail
            },
            invoiceBillings: updatedBillings
        }));

        setIsFormChanged(true);
    };

    const handleBillingInputChange = (index, nameOrEvent, value = null) => {
        const updatedBilling = [...formData.invoiceBillings];
        
        if (typeof nameOrEvent === 'object') {
            const { name, value: eventValue } = nameOrEvent.target;
            updatedBilling[index] = {
                ...updatedBilling[index],
                [name]: eventValue,
            };
        } else {
            updatedBilling[index] = {
                ...updatedBilling[index],
                [nameOrEvent]: value,
            };
        }
    
        setFormData((prevData) => ({
            ...prevData,
            invoiceBillings: updatedBilling
        }));
        setIsFormChanged(true);
    };
    
    const handleAddNewItem = () => {
        const newItem = {
            id: Date.now(),
            item: '',
            commercialQuantity: '',
            commercialUnitValue: '',
            discountValue: '',
            totalAmount: ''
        };
        setFormData((prevData) => ({
            ...prevData,
            invoiceItems: [...prevData.invoiceItems, newItem]
        }));
    };

    const handleAddNewBilling = () => {
        const newBilling = {
            id: Date.now(),
            installmentNumber: formData.invoiceBillings.length + 1,
            installmentAmount: '',
            installmentDueDate: ''
        };
        setFormData((prevData) => ({
            ...prevData,
            invoiceBillings: [...prevData.invoiceBillings, newBilling]
        }));
    };

    const handleAddNewPayment = () => {
        const newPayment = {
            id: Date.now(),  // Gera um ID temporário único
            paymentMethod: '0',
            paymentType: '',
            paymentDate: '',
            paymentAmount: ''
        };
        setFormData((prevData) => ({
            ...prevData,
            invoicePayment: {
                ...prevData.invoicePayment,
                invoicePaymentsDetail: [...prevData.invoicePayment.invoicePaymentsDetail, newPayment]
            }
        }));
    };

    const handleDeleteItem = (itemId) => {
        const updatedItems = formData.invoiceItems.filter((invoiceItem) => invoiceItem.id !== itemId);

        setFormData((prevData) => ({
            ...prevData,
            invoiceItems: updatedItems
        }));
        setIsFormChanged(true);

        // Chama a função de cálculo de impostos com os itens atualizados
        //calculateTaxes(updatedItems);
    };

    const handleDeletePayment = (paymentId) => {
        setFormData((prevData) => {
            // Filtrar as formas de pagamento para remover a que foi deletada
            const updatedPaymentsDetail = prevData.invoicePayment.invoicePaymentsDetail.filter((payment) => payment.id !== paymentId);
            
            // Verificar se ainda existe algum pagamento a prazo (paymentMethod === 'A_PRAZO')
            const hasInstallmentPayment = updatedPaymentsDetail.some((payment) => payment.paymentMethod === 'A_PRAZO');
            
            // Se não houver mais pagamentos a prazo, limpa as parcelas (invoiceBillings)
            const updatedBillings = hasInstallmentPayment ? prevData.invoiceBillings : [];
    
            return {
                ...prevData,
                invoicePayment: {
                    ...prevData.invoicePayment,
                    invoicePaymentsDetail: updatedPaymentsDetail
                },
                invoiceBillings: updatedBillings
            };
        });
    };

    const handleDeleteBilling = (billingId) => {
        setFormData((prevData) => {
            // Filtra as parcelas que permanecem após a exclusão
            const updatedBillings = prevData.invoiceBillings.filter((billing) => billing.id !== billingId);
            
            // Renumera as parcelas restantes
            const renumberedBillings = updatedBillings.map((billing, index) => ({
                ...billing,
                installmentNumber: index + 1
            }));
    
            return {
                ...prevData,
                invoiceBillings: renumberedBillings
            };
        });
    };

    const handleRawDateChange = (e, index) => {
        const value = e.target.value || '';
        const field = e.target.name;
    
        if (value && value.length === 10) {
            const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
    
            if (isValid(parsedDate)) {
                if (field === 'paymentDate') {
                    handlePaymentInputChange(index, field, parsedDate);
                } else if (field === 'installmentDueDate') {
                    handleBillingInputChange(index, field, parsedDate);
                }
            } else {
                if (field === 'paymentDate') {
                    handlePaymentInputChange(index, field, null);
                } else if (field === 'installmentDueDate') {
                    handleBillingInputChange(index, field, null);
                }
            }
        } else {
            if (field === 'paymentDate') {
                handlePaymentInputChange(index, field, null);
            } else if (field === 'installmentDueDate') {
                handleBillingInputChange(index, field, null);
            }
        }
    };
    
    const validateRequiredFields = () => {
        const errors = {};

        // Verificar os campos básicos obrigatórios
        if (!formData.natureOperation) errors.natureOperation = true;
        if (!formData.destinationOperation) errors.destinationOperation = true;
        if (!formData.consumerPresentIndicator) errors.consumerPresentIndicator = true;

        if (!formData.customer?.id) errors.customer = true;
    
        // Verificar os itens da nota fiscal
        const invoiceItemsErrors = formData.invoiceItems.map((item) => ({
            itemError: !item.item, // Verifica se o item está preenchido
            quantityError: !item.commercialQuantity, // Verifica se a quantidade está preenchida
        }));
    
        // Se algum campo obrigatório de um item estiver errado, adiciona ao errors
        if (invoiceItemsErrors.some((itemError) => itemError.itemError || itemError.quantityError || itemError.unitValueError)) {
            errors.invoiceItems = invoiceItemsErrors;
        }

        // Validação da transportadora
        if (['CONTRATACAO_POR_CONTA_DO_REMETENTE', 'CONTRATACAO_POR_CONTA_DO_DESTINATARIO', 'CONTRATACAO_POR_CONTA_DE_TERCEIROS'].includes(formData.deliveryMode)) {
            if (!formData.carrier) {
                errors.carrier = true;
            }
        } else if (formData.deliveryMode === 'PROPRIO_POR_CONTA_DO_REMETENTE') {
            // Verifica se os campos de veículo estão preenchidos
            if (!formData.invoiceCarrier.vehiclePlate) {
                errors.vehiclePlate = true;
            }
            if (!formData.invoiceCarrier.vehicleProvince) {
                errors.vehicleProvince = true;
            }
        }

        // Validação das formas de pagamento
        const invoicePaymentErrors = formData.invoicePayment.invoicePaymentsDetail.map((payment) => ({
            paymentMethodError: !payment.paymentMethod,
            paymentTypeError: !payment.paymentType,
            paymentAmountError: !payment.paymentAmount,
        }));

        // Se algum campo obrigatório de um pagamento estiver errado, adiciona ao errors
        if (invoicePaymentErrors.some((paymentError) => paymentError.paymentMethodError || paymentError.paymentTypeError || paymentError.paymentAmountError)) {
            errors.invoicePayments = invoicePaymentErrors;
        }
    
        // Validação das parcelas de cobrança (se houver pagamento a prazo)
        if (formData.invoicePayment.invoicePaymentsDetail.some((payment) => payment.paymentMethod === 'A_PRAZO')) {
            const invoiceBillingErrors = formData.invoiceBillings.map((billing) => ({
                installmentAmountError: !billing.installmentAmount,
                installmentDueDateError: !billing.installmentDueDate
            }));

            // Se algum campo obrigatório de uma parcela estiver errado, adiciona ao errors
            if (invoiceBillingErrors.some((billingError) => billingError.installmentAmountError || billingError.installmentDueDateError)) {
                errors.invoiceBillings = invoiceBillingErrors;
            }
        }

        const countErros = Object.keys(errors).length;

        if (countErros > 0) {
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        }

        //console.log(errors);
   
        return errors;
    };

    const handleSave = async () => {
        const errors = validateRequiredFields();
    
        if (Object.keys(errors).length > 0) {
            // Existem erros, destacar os campos e não salvar
            setFormErrors(errors);
            return;
        }
    
        // Se não houver erros, continue com o salvamento
        setFormErrors({});
        setIsFormChanged(false);
    
        try {
            const itemsToSend = formData.invoiceItems.map((invoiceItem, index) => {
                const isTemporaryId = typeof invoiceItem.id === 'number' && invoiceItem.id > 1e12;
    
                // Desestruturando somente o item para remover a propriedade description de invoiceItem.item
                const { description: itemDescription, ...itemWithoutDescription } = invoiceItem.item || {};
                const updatedInvoiceItem = {
                    ...invoiceItem,
                    discountValue: typeof invoiceItem.discountValue === 'string'? 
                                        (invoiceItem.discountValue ? invoiceItem.discountValue.replace(',', '.') : '0.00')
                                        : typeof invoiceItem.discountValue === 'number'? (isNaN(invoiceItem.discountValue) ? 0 
                                            : invoiceItem.discountValue.toFixed(2))
                                                : 0,
                    item: itemWithoutDescription,
                    code: index + 1
                };
    
                if (isTemporaryId) {
                    const { id, salesCategory, unitMeasurement, paymentMethod, ...rest } = updatedInvoiceItem;
                    return rest;
                }
    
                const { salesCategory, unitMeasurement, paymentMethod, ...rest } = updatedInvoiceItem;
                return rest;
            });
    
            const paymentsToSend = formData.invoicePayment.invoicePaymentsDetail.map(payment => {
                const isTemporaryId = typeof payment.id === 'number' && payment.id > 1e12;
    

                if (typeof payment.paymentAmount === 'string') {
                    payment.paymentAmount = parseFloat(payment.paymentAmount.replace(',', '.')).toFixed(2);
                    payment.paymentAmount = isNaN(payment.paymentAmount) ? 0 : payment.paymentAmount;
                } else if (typeof payment.paymentAmount === 'number') {
                    payment.paymentAmount = isNaN(payment.paymentAmount) ? 0 : payment.paymentAmount.toFixed(2);
                }

                if (isTemporaryId) {
                    const { id, ...rest } = payment;
                    return rest;
                }

                const { ...rest } = payment;
                return rest;
            });

            const billingsToSend = formData.invoiceBillings.map(billing => {
                const isTemporaryId = typeof billing.id === 'number' && billing.id > 1e12;
    

                if (typeof billing.installmentAmount === 'string') {
                    billing.installmentAmount = parseFloat(billing.installmentAmount.replace(',', '.'));
                    billing.installmentAmount = isNaN(billing.installmentAmount) ? 0 : billing.installmentAmount;
                } else if (typeof billing.installmentAmount === 'number') {
                    billing.installmentAmount = isNaN(billing.installmentAmount) ? 0 : billing.installmentAmount.toFixed(2);
                }

                if (isTemporaryId) {
                    const { id, ...rest } = billing;
                    return rest;
                }

                const { ...rest } = billing;
                return rest;
            });

            const vehiclePlateFormatted = formData.invoiceCarrier.vehiclePlate ? removeFormatting(formData.invoiceCarrier.vehiclePlate) : '';
    
            const invoiceCarrier = {
                ...formData.invoiceCarrier,
                vehiclePlate: vehiclePlateFormatted
            };
    
            if (!invoiceCarrier.id) {
                delete invoiceCarrier.id;
            }

            const customerId = formData.customer.id;
            const carrierId = formData.carrier;

            const customer = {
                ...formData.customer,
                id: customerId
            };

            const carrier = {
                ...formData.carrier,
                id: carrierId
            };

            const deliveryValue = formData.totalDeliveryValue ? typeof formData.totalDeliveryValue === 'string'? parseFloat(formData.totalDeliveryValue.replace(',', '.')): formData.totalDeliveryValue : 0;
            const insuranceValue = formData.totalInsuranceValue ? typeof formData.totalInsuranceValue === 'string'? parseFloat(formData.totalInsuranceValue.replace(',', '.')): formData.totalInsuranceValue : 0;
            const otherIncidentalExpenses = formData.totalOtherIncidentalExpenses ? typeof formData.totalOtherIncidentalExpenses === 'string'? parseFloat(formData.totalOtherIncidentalExpenses.replace(',', '.')): formData.totalOtherIncidentalExpenses : 0;

            const dataToSend = {
                ...formData,
                natureOperation: showCustomNatureOperation ? customNatureOperation : formData.natureOperation,
                invoiceItems: itemsToSend,
                invoiceBillings: billingsToSend,
                invoiceCarrier,
                invoicePayment: {
                    ...formData.invoicePayment,
                    paymentChange: '', //Troco
                    invoicePaymentsDetail: paymentsToSend
                },
                customer: customer,
                carrier: carrier,
                totalDeliveryValue: deliveryValue,
                totalInsuranceValue: insuranceValue,
                totalOtherIncidentalExpenses: otherIncidentalExpenses
            };
    
            if (invoice.id) {
                await axios.put(`/api/invoices/outgoing/${invoice.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Nota fiscal editada com sucesso.' });
            } else {
                await axios.post('/api/invoices/outgoing', dataToSend);
                setAlert({ type: 'success', message: 'Nota fiscal adicionada com sucesso.' });
            }
    
            setInProp(false);
            setTimeout(() => {
                navigate('/invoices/outgoing', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
            console.error('Erro ao salvar nota fiscal', error);
        }
    };

    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/invoices/outgoing', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-3">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h3 className="mb-3">{invoice.id ? 'Editar Nota Fiscal' : 'Nova Nota Fiscal'}</h3>
                        <form>
                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em' }}>
                                    <span>Informações Básicas</span>
                                    <span className={`ms-3 ${formatStatus(invoice.status).className}`} style={{ padding: '4px 8px', borderRadius: '4px' }} onClick={() => handleShowInfoErrorModal(invoice.invoiceProtocol)}>
                                        {formatStatus(invoice.status).label}
                                        {invoice.invoiceProtocol && invoice.status === 'PROCESSADA_COM_ERRO' && (
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                className="ms-2 text-warning"
                                                title="Ver detalhes do erro"
                                                onClick={() => handleShowInfoErrorModal(invoice.invoiceProtocol)}
                                            />
                                        )}
                                    </span>
                                </h5>
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-6">
                                        <label className="form-label">Natureza da Operação <span className="text-danger">*</span></label>
                                        <select 
                                            className="form-select"
                                            name="natureOperationType"
                                            value={formData.natureOperationType}
                                            onChange={handleInputChange}
                                            required={true}
                                        >
                                            <option value="VENDA_MERCADORIA">Venda de mercadoria adquirida ou recebida de terceiros</option>
                                            <option value="VENDA_PROD_ESTABELECIMENTO">Venda de produção do estabelecimento</option>
                                            <option value="VENDA_ATIVO_IMOBILIZADO">Venda de ativo imobilizado</option>
                                            <option value="VENDA_MERCADORIA_ENTREGA_FUTURA">Venda de mercadoria para entrega futura</option>
                                            <option value="VENDA_ORDEM">Venda à ordem</option>
                                            <option value="VENDA_CONSUMIDOR_FINAL">Venda para consumidor final</option>
                                            <option value="REMESSA_INDUSTRIALIZACAO">Remessa para industrialização por encomenda</option>
                                            <option value="VENDA_EXPORTACAO">Venda para exportação</option>
                                            <option value="DEVOLUCAO_VENDA">Devolução de venda</option>
                                            <option value="BONIFICACAO_DOACAO_BRINDES">Bonificação, doação ou brindes</option>
                                            <option value="VENDA_ZONA_FRANCA_MANAUS">Venda para Zona Franca de Manaus</option>
                                        </select>
                                    </div>
                                    <div className={`col-12 col-lg-6 mt-3 mt-lg-0 ${formErrors.consumerPresentIndicator ? 'has-error' : ''}`}>
                                    <label className="form-label">Indicador Presença do Consumidor <span className="text-danger">*</span></label>
                                        <select 
                                            className="form-select"
                                            name="consumerPresentIndicator"
                                            value={formData.consumerPresentIndicator}
                                            onChange={handleInputChange}
                                            required={true}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="NAO_APLICA">Não se aplica</option>
                                            <option value="OPERACAO_PRESENCIAL">Operação presencial</option>
                                            <option value="OPERACAO_NAO_PRESENCIAL_INTERNET">Operação não presencial - Internet</option>
                                            <option value="OPERACAO_NAO_PRESENCIAL_TELEATENDIMENTO">Operação não presencial - Teleatendimento</option>
                                            {/*<option value="NFCE_EM_OPERACAO_COM_ENTREGA_DOMICILIO">NFC-e em operação com entrega a domicílio</option>*/}
                                            <option value="OPERACAO_PRESENCIAL_FORA_ESTABELECIMENTO">Operação presencial, fora do estabelecimento</option>
                                            <option value="OPERACAO_NAO_PRESENCIAL_OUTROS">Operação não presencial - Outros</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-0">
                                    <div className={`col-12 col-lg-6 ${formErrors.customer ? 'has-error' : ''}`}>
                                        <label className="form-label mb-0">
                                            {formErrors.customer}Cliente <span className="text-danger">*</span>
                                        </label>
                                        <AsyncSelect2
                                            url="/api/customers"
                                            value={selectedCustomer}
                                            onChange={handleCustomerChange}
                                            valueField="id"
                                            labelField="legalName"
                                            placeholder="Selecione o Cliente"
                                            initialOptions={selectedCustomer ? [selectedCustomer] : []}
                                            labelFormatter={(item) => `${item.legalName}`}
                                            extraFields={['addresses', 'endConsumer', 'simplesNacional', 'icmsTaxPayerIndicator']}
                                            showClear
                                            required={true}
                                            isDisabled={handleDisableCustomer()}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                                        <label className="form-label">Destino da Operação</label>
                                        <input
                                            type="text"
                                            name="destinationOperation"
                                            readOnly={true}
                                            className="form-control readonly-style"
                                            value={formData.destinationOperation? formData.destinationOperation === '1' || formData.destinationOperation === 'OPERACAO_INTERNA'? 'Operação interna': 'Operação interestadual': ''}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Itens da Nota Fiscal</h5>
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-sm" 
                                        onClick={handleAddNewItem}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                                <div className="invoice-items-page">
                                    {formData.invoiceItems.length === 0 ? (
                                        <li className="list-group-item d-flex align-items-center mt-3">
                                        <p>Nenhum item adicionado.<br/>Adicione pelo menos um item para Salvar.</p>
                                        </li>
                                    ) : (
                                        <>
                                            <div className="list-group-item-header">
                                                <div>Item <span className="text-danger">*</span></div>
                                                <div>UM</div>
                                                <div>Quantidade <span className="text-danger">*</span></div>
                                                <div>Valor Unitário</div>
                                                <div>Desconto</div>
                                                <div>Valor Total</div>
                                                <div>Ações</div>
                                            </div>

                                            <ul className="list-group mt-3">
                                                {formData.invoiceItems.map((invoiceItem, index) => {
                                                    const itemErrors = formErrors.invoiceItems && formErrors.invoiceItems[index];

                                                    return (
                                                        <li className="list-group-item d-flex align-items-center" key={invoiceItem.id || `new-${index}`}>
                                                            <div className={itemErrors && itemErrors.itemError ? 'has-error' : ''}>
                                                                <AsyncSelect2
                                                                    url="/api/items"
                                                                    value={invoiceItem.item ? { value: invoiceItem.item.id, label: invoiceItem.item.name || invoiceItem.item.description } : null}
                                                                    onChange={(selectedOption) => handleItemSelectionChange(index, selectedOption)}
                                                                    valueField="id"
                                                                    labelField="name"
                                                                    placeholder="Selecione o Item"
                                                                    initialOptions={invoiceItem ? [invoiceItem] : []}
                                                                    labelFormatter={(invoiceItem) => (
                                                                        <span title={invoiceItem.name}>
                                                                            {invoiceItem.name}
                                                                        </span>
                                                                    )}
                                                                    required={true}
                                                                    showClear={true}
                                                                    className={itemErrors && itemErrors.itemError ? 'has-error' : ''}
                                                                    extraFields={['unitMeasurement', 'salesCategory', 'taxRule', 'currentPrice']}
                                                                />
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control readonly-style"
                                                                    name="um"
                                                                    value={invoiceItem?.unitMeasurement?.initials || ''}
                                                                    readOnly
                                                                    style={{ textTransform: 'uppercase' }}
                                                                    autoComplete="off"
                                                                />
                                                            </div>

                                                            <div className={itemErrors && itemErrors.quantityError ? 'has-error' : ''}>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="commercialQuantity"
                                                                    step={invoiceItem?.unitMeasurement?.decimals ? `0.${'0'.repeat(invoiceItem.unitMeasurement.decimals - 1)}0` : '0'}
                                                                    value={invoiceItem.commercialQuantity}
                                                                    onChange={(e) => handleQuantityChange(index, e, invoiceItem?.unitMeasurement?.decimals || 0)}
                                                                    placeholder="Qtd"
                                                                    required={true}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className={itemErrors && itemErrors.unitValueError ? 'has-error' : ''}>
                                                                <CurrencyInput
                                                                    name="commercialUnitValue"
                                                                    placeholder="R$ 0,00"
                                                                    value={invoiceItem.commercialUnitValue ? parseFloat(invoiceItem.commercialUnitValue).toFixed(2) : '0.00'}
                                                                    decimalsLimit={2}
                                                                    fixedDecimalLength={2}
                                                                    decimalSeparator=","
                                                                    groupSeparator="."
                                                                    prefix="R$ "
                                                                    className="form-control readonly-style"
                                                                    readOnly={true}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div>
                                                                <CurrencyInput
                                                                    id="discountValue"
                                                                    name="discountValue"
                                                                    placeholder="R$ 0,00"
                                                                    value={invoiceItem.discountValue || ''}
                                                                    decimalsLimit={2}
                                                                    fixedDecimalLength={2}
                                                                    decimalSeparator=","
                                                                    groupSeparator="."
                                                                    prefix="R$ "
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                    onValueChange={(value) => handleItemInputChange(index, 'discountValue', value)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <CurrencyInput
                                                                    name="totalAmount"
                                                                    placeholder="R$ 0,00"
                                                                    value={invoiceItem.totalAmount || '0,00'}
                                                                    decimalsLimit={2}
                                                                    fixedDecimalLength={2}
                                                                    decimalSeparator=","
                                                                    groupSeparator="."
                                                                    prefix="R$ "
                                                                    className="form-control readonly-style"
                                                                    readOnly={true}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm border-secondary custom-btn"
                                                                    style={{ opacity: formData.invoiceItems.length === 1 ? 0.35 : 0.65 }}
                                                                    title="Excluir item"
                                                                    onClick={() => handleDeleteItem(invoiceItem.id)}
                                                                    disabled={formData.invoiceItems.length === 1}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Informações de Pagamento</h5>
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-sm" 
                                        onClick={handleAddNewPayment}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                                <div className="invoice-payments-page">
                                    {formData.invoicePayment.invoicePaymentsDetail.length === 0 ? (
                                        <li className="list-group-payment d-flex align-payments-center mt-3">
                                        <p>Nenhuma forma de pagamento adicionada.<br/>Adicione pelo menos uma para Salvar.</p>
                                        </li>
                                    ) : (
                                        <>
                                            <div className="list-group-payment-header">
                                                <div>Forma de Pagamento <span className="text-danger">*</span></div>
                                                <div>Meio de pagamento <span className="text-danger">*</span></div>
                                                <div>Valor do Pagto <span className="text-danger">*</span></div>
                                                <div>Ações</div>
                                            </div>

                                            <ul className="list-group mt-3">
                                                {formData.invoicePayment.invoicePaymentsDetail.map((invoicePaymentDetail, index) => {
                                                    const paymentErrors = formErrors.invoicePayments && formErrors.invoicePayments[index];

                                                    return (
                                                        <li className="list-group-payment d-flex align-payments-center" key={invoicePaymentDetail.id || `new-${index}`}>
                                                            <div>
                                                                <select 
                                                                    className="form-select"
                                                                    name="paymentMethod"
                                                                    value={invoicePaymentDetail?.paymentMethod || ''}
                                                                    onChange={(e) => handlePaymentInputChange(index, e)} 
                                                                >
                                                                    <option value="A_VISTA">Pagamento À vista</option>
                                                                    <option value="A_PRAZO">Pagamento a prazo</option>
                                                                </select>
                                                            </div>
                                                            <div className={paymentErrors && paymentErrors.paymentTypeError ? 'has-error' : ''}>
                                                                <select 
                                                                    className="form-select"
                                                                    name="paymentType"
                                                                    value={invoicePaymentDetail?.paymentType || ''}
                                                                    onChange={(e) => handlePaymentInputChange(index, e)} 
                                                                >
                                                                    {(invoicePaymentDetail?.paymentMethod === 'A_PRAZO' ? paymentTypes.prazo : paymentTypes.vista).map(option => (
                                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className={paymentErrors && paymentErrors.paymentAmountError ? 'has-error' : ''}>
                                                                <CurrencyInput
                                                                    name="paymentAmount"
                                                                    placeholder="R$ 0,00"
                                                                    value={invoicePaymentDetail.paymentAmount || ''}
                                                                    decimalsLimit={2}
                                                                    fixedDecimalLength={2}
                                                                    onValueChange={(value) => handlePaymentInputChange(index, 'paymentAmount', value)}
                                                                    decimalSeparator=","
                                                                    groupSeparator="."
                                                                    prefix="R$ "
                                                                    className="form-control"
                                                                    /*readOnly={invoicePaymentDetail?.paymentMethod === 'A_PRAZO'}
                                                                    className={`form-control ${invoicePaymentDetail?.paymentMethod === 'A_PRAZO' ? 'readonly-style' : ''}`}*/
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm border-secondary custom-btn"
                                                                    title="Excluir forma de pagamento"
                                                                    onClick={() => handleDeletePayment(invoicePaymentDetail.id)}
                                                                    disabled={formData.invoicePayment.invoicePaymentsDetail.length === 1}
                                                                    style={{opacity: formData.invoicePayment.invoicePaymentsDetail.length === 1? 0.35: 0.65}}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            </div>

                            {formData.invoicePayment.invoicePaymentsDetail.some((invoiceDetail) => invoiceDetail.paymentMethod === 'A_PRAZO') && (
                                <>
                                    <div className="mb-3 p-3 border rounded">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="mb-0">Informações da Cobrança</h5>
                                            <button 
                                                type="button" 
                                                className="btn btn-primary btn-sm" 
                                                onClick={handleAddNewBilling}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                        </div>
                                        <div className="invoice-billings-page">
                                            {formData.invoiceBillings.length === 0 ? (
                                                <li className="list-group-billing d-flex align-billings-center mt-3">
                                                <p>Nenhuma cobrança/parcela adicionada.<br/>Adicione pelo menos uma para Salvar.</p>
                                                </li>
                                            ) : (
                                                <>
                                                    <div className="list-group-billing-header">
                                                        <div>Número da Parcela <span className="text-danger">*</span></div>
                                                        <div>Data de Vencto <span className="text-danger">*</span></div>
                                                        <div>Valor da Parcela <span className="text-danger">*</span></div>
                                                        <div>Ações</div>
                                                    </div>

                                                    <ul className="list-group mt-3">
                                                        {formData.invoiceBillings.map((invoiceBilling, index) =>  {
                                                            const billingErrors = formErrors.invoiceBillings && formErrors.invoiceBillings[index];

                                                            return (
                                                                <li className="list-group-billing d-flex align-billings-center" key={invoiceBilling.id || `new-${index}`}>
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control readonly-style"
                                                                            name="installmentNumber"
                                                                            value={invoiceBilling?.installmentNumber || ''}
                                                                            readOnly
                                                                            required={true}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                    <div className={billingErrors && billingErrors.installmentDueDateError ? 'has-error' : ''}>
                                                                        <DatePicker
                                                                            name="installmentDueDateDt"
                                                                            selected={invoiceBilling.installmentDueDate ? new Date(invoiceBilling.installmentDueDate) : null}
                                                                            onChange={(date) => handleBillingInputChange(index, 'installmentDueDate', date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="form-control"
                                                                            locale="pt-BR"
                                                                            showPopperArrow={false}
                                                                            onChangeRaw={(e) => handleRawDateChange(e, index)}
                                                                            customInput={(
                                                                                <ReactInputMask
                                                                                    mask="99/99/9999"
                                                                                    name="installmentDueDate"
                                                                                    value={invoiceBilling.installmentDueDate ? format(new Date(invoiceBilling.installmentDueDate), 'dd/MM/yyyy') : ''}
                                                                                    onChange={(e) => handleRawDateChange(e, index)}
                                                                                    className="form-control"
                                                                                    required={true}
                                                                                    autoComplete="off"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className={billingErrors && billingErrors.installmentAmountError ? 'has-error' : ''}>
                                                                        <CurrencyInput
                                                                            name="installmentAmount"
                                                                            placeholder="R$ 0,00"
                                                                            value={invoiceBilling.installmentAmount || ''}
                                                                            decimalsLimit={2}
                                                                            fixedDecimalLength={2}
                                                                            onValueChange={(value) => handleBillingInputChange(index, 'installmentAmount', value)}
                                                                            decimalSeparator=","
                                                                            groupSeparator="."
                                                                            prefix="R$ "
                                                                            className="form-control"
                                                                            required={true}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm border-secondary custom-btn"
                                                                            title="Excluir parcela"
                                                                            onClick={() => handleDeleteBilling(invoiceBilling.id)}
                                                                            disabled={formData.invoiceBillings.length === 1}
                                                                            style={{opacity: formData.invoiceBillings.length === 1? 0.35: 0.65}}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Informações do Transporte</h5>
                                <div className="mb-3">
                                    <label className="form-label">Modalidade do Frete <span className="text-danger">*</span></label>
                                    <select 
                                        className="form-select"
                                        name="deliveryMode"
                                        value={formData.deliveryMode}
                                        onChange={handleInputChange}
                                        required={true}
                                    >
                                        <option value="CONTRATACAO_POR_CONTA_DO_REMETENTE">Contratação do frete por conta do remetente (CIF)</option>
                                        <option value="CONTRATACAO_POR_CONTA_DO_DESTINATARIO">Contratação do frete por conta do destinatário (FOB)</option>
                                        <option value="CONTRATACAO_POR_CONTA_DE_TERCEIROS">Contratação do frete por conta de terceiros</option>
                                        <option value="PROPRIO_POR_CONTA_DO_REMETENTE">Transporte próprio por conta do remetente</option>
                                        <option value="PROPRIO_POR_CONTA_DO_DESTINATARIO">Transporte próprio por conta do destinatário</option>
                                        <option value="SEM_OCORRENCIA_TRANSPORTE">Sem ocorrência de transporte</option>
                                    </select>
                                </div>
                                {['CONTRATACAO_POR_CONTA_DO_REMETENTE', 'CONTRATACAO_POR_CONTA_DO_DESTINATARIO', 'CONTRATACAO_POR_CONTA_DE_TERCEIROS'].includes(formData.deliveryMode) && (
                                    <>
                                    <div className={`mb-3 ${formErrors.carrier ? 'has-error' : ''}`}>
                                        <label className="form-label">Transportadora <span className="text-danger">*</span></label>
                                        <AsyncSelect2
                                            url="/api/carriers"
                                            value={selectedCarrier}
                                            onChange={handleCarrierChange}
                                            valueField="id"
                                            labelField="legalName"
                                            placeholder="Selecione a transportadora"
                                            initialOptions={selectedCarrier ? [selectedCarrier] : []}
                                            labelFormatter={(item) => `${item.legalName}`}
                                            showClear
                                            required={['CONTRATACAO_POR_CONTA_DO_REMETENTE', 'CONTRATACAO_POR_CONTA_DO_DESTINATARIO', 'CONTRATACAO_POR_CONTA_DE_TERCEIROS'].includes(formData.deliveryMode)}
                                        />
                                    </div>

                                    <div className="row mb-0">
                                        <div className={`col-12 col-md-6 mt-3 mt-md-0`}>
                                            <label className="form-label">Valor do Frete</label>
                                            <CurrencyInput
                                                id="input-totalDeliveryValue"
                                                name="totalDeliveryValue"
                                                placeholder="R$ 0,00"
                                                value={formData.totalDeliveryValue}
                                                decimalsLimit={2}
                                                fixedDecimalLength={2}
                                                decimalSeparator=","
                                                groupSeparator="."
                                                prefix="R$ "
                                                className="form-control"
                                                required
                                                onValueChange={(value, name) => handleCurrencyChange(value, name)}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className={`col-12 col-md-6 mt-3 mt-md-0`}>
                                            <label className="form-label">Valor do Seguro</label>
                                            <CurrencyInput
                                                id="input-totalInsuranceValue"
                                                name="totalInsuranceValue"
                                                placeholder="R$ 0,00"
                                                onValueChange={(value, name) => handleCurrencyChange(value, name)}
                                                value={formData.totalInsuranceValue}
                                                decimalsLimit={2}
                                                fixedDecimalLength={2}
                                                decimalSeparator=","
                                                groupSeparator="."
                                                prefix="R$ "
                                                className="form-control"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    </>
                                )}
                                {['PROPRIO_POR_CONTA_DO_REMETENTE'].includes(formData.deliveryMode) && (
                                <div className="row mb-0">
                                    <div className={`col-12 col-md-4 ${formErrors.vehiclePlate ? 'has-error' : ''}`}>
                                        <label className="form-label">Placa do veículo{['PROPRIO_POR_CONTA_DO_REMETENTE'].includes(formData.deliveryMode) && (<span className="text-danger"> *</span>)}</label>
                                        <ReactInputMask mask="aaa-9*99" value={formData.invoiceCarrier.vehiclePlate || ''} onChange={handleInputChange} autoComplete="off">
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" name="vehiclePlate" required={['PROPRIO_POR_CONTA_DO_REMETENTE'].includes(formData.deliveryMode)} />}
                                        </ReactInputMask>
                                    </div>
                                    <div className={`col-12 col-md-8 mt-3 mt-md-0 ${formErrors.vehicleProvince ? 'has-error' : ''}`}>
                                        <label className="form-label">Estado do veículo{['PROPRIO_POR_CONTA_DO_REMETENTE'].includes(formData.deliveryMode) && (<span className="text-danger"> *</span>)}</label>
                                        <select 
                                            className="form-select"
                                            name="vehicleProvince"
                                            value={formData.invoiceCarrier.vehicleProvince}
                                            onChange={handleInputChange}
                                            required={['PROPRIO_POR_CONTA_DO_REMETENTE'].includes(formData.deliveryMode)}
                                        >
                                            <option value="">Selecione o Estado</option>
                                            <option value="AC">Acre (AC)</option>
                                            <option value="AL">Alagoas (AL)</option>
                                            <option value="AP">Amapá (AP)</option>
                                            <option value="AM">Amazonas (AM)</option>
                                            <option value="BA">Bahia (BA)</option>
                                            <option value="CE">Ceará (CE)</option>
                                            <option value="DF">Distrito Federal (DF)</option>
                                            <option value="ES">Espírito Santo (ES)</option>
                                            <option value="GO">Goiás (GO)</option>
                                            <option value="MA">Maranhão (MA)</option>
                                            <option value="MT">Mato Grosso (MT)</option>
                                            <option value="MS">Mato Grosso do Sul (MS)</option>
                                            <option value="MG">Minas Gerais (MG)</option>
                                            <option value="PA">Pará (PA)</option>
                                            <option value="PB">Paraíba (PB)</option>
                                            <option value="PR">Paraná (PR)</option>
                                            <option value="PE">Pernambuco (PE)</option>
                                            <option value="PI">Piauí (PI)</option>
                                            <option value="RJ">Rio de Janeiro (RJ)</option>
                                            <option value="RN">Rio Grande do Norte (RN)</option>
                                            <option value="RS">Rio Grande do Sul (RS)</option>
                                            <option value="RO">Rondônia (RO)</option>
                                            <option value="RR">Roraima (RR)</option>
                                            <option value="SC">Santa Catarina (SC)</option>
                                            <option value="SP">São Paulo (SP)</option>
                                            <option value="SE">Sergipe (SE)</option>
                                            <option value="TO">Tocantins (TO)</option>
                                        </select>
                                    </div>
                                </div>
                                )}
                            </div>

                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{ marginBottom: '1em' }}>Impostos e Valores Adicionais</h5>
                                <div className="mb-3 row">
                                    <div className={`col-12 col-xl-2`}>
                                        <label className="form-label">ICMS</label>
                                        <CurrencyInput
                                            name="icmsTotalValue"
                                            placeholder="R$ 0,00"
                                            value={formData.icmsTotalValue ? parseFloat(formData.icmsTotalValue).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label">ICMS Substituição</label>
                                        <CurrencyInput
                                            name="icmsTotalValueSt"
                                            placeholder="R$ 0,00"
                                            value={formData.icmsTotalValueSt ? parseFloat(formData.icmsTotalValueSt).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label">Valor Isento do ICMS</label>
                                        <CurrencyInput
                                            name="icmsTaxExemptValue"
                                            placeholder="R$ 0,00"
                                            value={formData.icmsTaxExemptValue ? parseFloat(formData.icmsTaxExemptValue).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label">IPI</label>
                                        <CurrencyInput
                                            name="ipiTotalValue"
                                            placeholder="R$ 0,00"
                                            value={formData.ipiTotalValue ? parseFloat(formData.ipiTotalValue).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label">PIS</label>
                                        <CurrencyInput
                                            name="pisTotalValue"
                                            placeholder="R$ 0,00"
                                            value={formData.pisTotalValue ? parseFloat(formData.pisTotalValue).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label">COFINS</label>
                                        <CurrencyInput
                                            name="cofinsTotalValue"
                                            placeholder="R$ 0,00"
                                            value={formData.cofinsTotalValue ? parseFloat(formData.cofinsTotalValue).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="mb-0 row">
                                    <div className={`col-12 col-xl-2`}>
                                        <label className="form-label">FCP</label>
                                        <CurrencyInput
                                            name="totalPovertyAlleviationFund"
                                            placeholder="R$ 0,00"
                                            value={formData.totalPovertyAlleviationFund ? parseFloat(formData.totalPovertyAlleviationFund).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2`}>
                                        <label className="form-label">FCP Substituição</label>
                                        <CurrencyInput
                                            name="totalPovertyAlleviationFundSt"
                                            placeholder="R$ 0,00"
                                            value={formData.totalPovertyAlleviationFundSt ? parseFloat(formData.totalPovertyAlleviationFundSt).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label">Total dos Itens</label>
                                        <CurrencyInput
                                            name="totalItemsValue"
                                            placeholder="R$ 0,00"
                                            value={formData.totalItemsValue ? parseFloat(formData.totalItemsValue).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label">Total de Descontos</label>
                                        <CurrencyInput
                                            name="totalDiscountValue"
                                            placeholder="R$ 0,00"
                                            value={formData.totalDiscountValue ? parseFloat(formData.totalDiscountValue).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label">Outras Despesas</label>
                                        <CurrencyInput
                                            id="input-totalOtherIncidentalExpenses"
                                            name="totalOtherIncidentalExpenses"
                                            placeholder="R$ 0,00"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name)}
                                            value={formData.totalOtherIncidentalExpenses}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className={`col-12 col-xl-2 mt-3 mt-xl-0`}>
                                        <label className="form-label"><strong>Total da Nota</strong></label>
                                        <CurrencyInput
                                            name="totalInvoiceValue"
                                            placeholder="R$ 0,00"
                                            value={formData.totalInvoiceValue ? parseFloat(formData.totalInvoiceValue).toFixed(2) : '0.00'}
                                            decimalsLimit={2}
                                            fixedDecimalLength={2}
                                            decimalSeparator=","
                                            groupSeparator="."
                                            prefix="R$ "
                                            className="form-control readonly-style"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{ marginBottom: '1em' }}>Informações Adicionais</h5>
                                <div className="mb-3">
                                    <label className="form-label">Informações Adicionais</label>
                                    <textarea
                                        name="additionalInformation"
                                        placeholder="Informações adicionais..."
                                        value={formData.additionalInformation}
                                        onChange={(e) => { setFormData({ ...formData, additionalInformation: e.target.value }); setIsFormChanged(true); }}
                                        rows="3"
                                        className="form-control"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                                
                                disabled={!invoice.id? false: !isFormChanged? true: false}
                            >
                                <FontAwesomeIcon icon={faSave} /> Salvar
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                        </form>
                    </div>
                ) : null}

                <AlertModal
                    show={!!showInfoErrorModal}
                    onHide={() => setShowInfoErrorModal(null)}
                    title="Retorno da SEFAZ"
                    message={
                        showInfoErrorModal && (
                            <>
                                <p><strong>Código do Status:</strong> {showInfoErrorModal.statusCode}</p>
                                <p><strong>Motivo:</strong> {showInfoErrorModal.statusReason}</p>
                                {showInfoErrorModal.messageCode && (
                                    <p><strong>Código da Mensagem:</strong> {showInfoErrorModal.messageCode}</p>
                                )}
                                {showInfoErrorModal.messageDescription && (
                                    <p><strong>Descrição da Mensagem:</strong> {showInfoErrorModal.messageDescription}</p>
                                )}
                            </>
                        )
                    }
                />
            </div>
        </CSSTransition>
    );
}

export default InvoiceEdit;
