import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import CurrencyInput from 'react-currency-input-field';

function ItemEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        active: true,
        unitMeasurement: null,
        itemGroup: null,
    });
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [selectedUnitMeasurement, setSelectedUnitMeasurement] = useState(null);
    const [selectedItemGroup, setSelectedItemGroup] = useState(null);
    const [selectedSalesCategory, setSelectedSalesCategory] = useState(null);
    const [selectedTaxRule, setSelectedTaxRule] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { item: initialItem, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [item, setItem] = useState(initialItem || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);

    const loadItem = useCallback(async () => {
        try {
            const response = await axios.get(`/api/items/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar item:', error);
            if (error.response && error.response.status === 404) {
                navigate('/items', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadItem();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setShowForm(true);
        }
    }, [axiosConfigured, id, loadItem]);
    
    useEffect(() => {
        setInProp(true);
    }, []);

    const initializeForm = (item) => {
        setItem(item);
        setFormData({
            name: item.name,
            description: item.description,
            active: item.active,
            barCode: item.barCode,
            currentPrice: item.currentPrice !== undefined && item.currentPrice !== null? Number(item.currentPrice).toFixed(2).replace('.', ','): '',
            sku: item.sku,
            unitMeasurement: item.unitMeasurement ? item.unitMeasurement.id : null,
            itemGroup: item.itemGroup ? item.itemGroup.id : null,
        });
        setSelectedUnitMeasurement(item.unitMeasurement ? {
            label: item.unitMeasurement.name,
            value: item.unitMeasurement.id,
        } : null);
        setSelectedItemGroup(item.itemGroup ? {
            label: item.itemGroup.name,
            value: item.itemGroup.id,
        } : null);
        setSelectedSalesCategory(item.salesCategory ? {
            label: item.salesCategory.name,
            value: item.salesCategory.id,
        } : null);
        setSelectedTaxRule(item.taxRule ? {
            label: item.taxRule.name,
            value: item.taxRule.id,
        } : null);
    };

    const validateRequiredFields = () => {
        const errors = {};

        if (!formData.name) errors.name = true;
        if (!selectedUnitMeasurement) errors.unitMeasurement = true;

        const countErros = Object.keys(errors).length;

        if (countErros > 0) {
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        }

        //console.log(errors);
        
        return errors;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const isChanged = prevData[name] !== value;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, [name]: value };
        });
    };

    const handleCurrencyChange = (value, name) => {
        if (value === undefined || value === null || value === '') {
          value = '';
        } else {
          value = value.trim();
          value = value.replace(/\./g, '');
          value = value.replace(',', '.');
        }
      
        setFormData((prevData) => {
          const isChanged = prevData[name] !== value;
          if (isChanged) {
            setIsFormChanged(true);
          }
          return { ...prevData, [name]: value };
        });
      };
      

    const handleSalesCategoryChange = (selectedOption) => {
        const selectedSalesCategory = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.label
        } : null;
        
        setSelectedSalesCategory(selectedOption);

        setFormData((prevData) => {
            const isChanged = prevData.salesCategory !== selectedSalesCategory;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, salesCategory: selectedSalesCategory };
        });
    };

    const handleTaxRuleChange = (selectedOption) => {
        const selectedTaxRule = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.label
        } : null;
        
        setSelectedTaxRule(selectedOption);

        setFormData((prevData) => {
            const isChanged = prevData.taxRule !== selectedTaxRule;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, taxRule: selectedTaxRule };
        });
    };

    const handleUnitMeasurementChange = (selectedOption) => {
        const selectedUnit = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.label,
        } : null;
        
        setSelectedUnitMeasurement(selectedOption);
        setFormData((prevData) => {
            const isChanged = prevData.unitMeasurement !== selectedUnit?.id;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, unitMeasurement: selectedUnit?.id || null };
        });
    };

    const handleItemGroupChange = (selectedOption) => {
        const selectedGroup = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.label,
        } : null;
        
        setSelectedItemGroup(selectedOption);
        setFormData((prevData) => {
            const isChanged = prevData.itemGroup !== selectedGroup?.id;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, itemGroup: selectedGroup?.id || null };
        });
    };

    const handleSave = async () => {
        const errors = validateRequiredFields();
    
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
    
        setFormErrors({});
        setIsFormChanged(false);
        
        if (formData.currentPrice === undefined || formData.currentPrice === null || formData.currentPrice === '') {
            formData.currentPrice = '';
        } else {
            formData.currentPrice = formData.currentPrice.replace(',', '.');
        }

        try {
            const dataToSend = {
                ...formData,
                unitMeasurement: selectedUnitMeasurement ? {
                    id: selectedUnitMeasurement.value,
                } : null,
                itemGroup: selectedItemGroup ? {
                    id: selectedItemGroup.value,
                } : null,
                salesCategory: selectedSalesCategory ? {
                    id: selectedSalesCategory.value,
                } : null,
                taxRule: selectedTaxRule && selectedTaxRule.value ? {
                    id: selectedTaxRule.value
                } : null
            };
    
            if (item.id) {
                await axios.put(`/api/items/${item.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Item editado com sucesso.' });
            } else {
                await axios.post('/api/items', dataToSend);
                setAlert({ type: 'success', message: 'Item adicionado com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/items', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
            console.error('Erro ao salvar item', error);
        }
    };
    
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/items', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    const renderGeneralData = () => (
        <div>
            <div className="mb-3 p-3 border rounded">
                <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                <div className={formErrors.name? 'mb-3 has-error' : 'mb-3'}>
                    <label className="form-label">Nome <span className="text-danger">*</span></label>
                    <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleInputChange}
                        required
                        maxLength={60}
                        autoComplete="off"
                    />
                </div>
                <div className={formErrors.unitMeasurement? 'mb-3 has-error' : 'mb-3'}>
                    <label className="form-label">Unidade de Medida <span className="text-danger">*</span></label>
                    <AsyncSelect2
                        url="/api/unit-measurements"
                        value={selectedUnitMeasurement}
                        onChange={handleUnitMeasurementChange}
                        valueField="id"
                        labelField="name"
                        placeholder="Selecione a Unidade de Medida"
                        initialOptions={selectedUnitMeasurement ? [selectedUnitMeasurement] : []}
                        labelFormatter={(item) => `${item.name}`}
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Grupo de Item</label>
                    <AsyncSelect2
                        url="/api/items/groups"
                        value={selectedItemGroup}
                        onChange={handleItemGroupChange}
                        valueField="id"
                        labelField="name"
                        placeholder="Selecione o Grupo de Item"
                        initialOptions={selectedItemGroup ? [selectedItemGroup] : []}
                        labelFormatter={(item) => `${item.name}`}
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Descrição</label>
                    <textarea
                        className="form-control"
                        name="description"
                        value={formData.description || ''}
                        onChange={handleInputChange}
                        rows="4"
                        required
                        maxLength={500}
                        autoComplete="off"
                    />
                </div>
                <div className="mb-0">
                    <label className="form-label">Ativo <span className="text-danger">*</span></label>
                    <select 
                        className="form-select"
                        name="active"
                        value={formData.active}
                        onChange={handleInputChange}>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                    </select>
                </div>
            </div>

            <div className="mb-3 p-3 border rounded">
                <h5 style={{marginBottom: 1 + 'em'}}>Configuração</h5>
                <div className="mb-3">
                    <label className="form-label">Categoria de Venda</label>
                    <AsyncSelect2
                        url="/api/sales-category"
                        value={selectedSalesCategory}
                        onChange={handleSalesCategoryChange}
                        valueField="id"
                        labelField="name"
                        placeholder="Selecione a categoria de venda"
                        initialOptions={selectedSalesCategory ? [selectedSalesCategory] : []}
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Regra fiscal</label>
                    <AsyncSelect2
                        url="/api/tax-rules"
                        value={selectedTaxRule}
                        onChange={handleTaxRuleChange}
                        valueField="id"
                        labelField="name"
                        placeholder="Selecione a regra fiscal"
                        initialOptions={selectedTaxRule ? [selectedTaxRule] : []}
                        showClear
                        fixedOption={{ label: 'Usar regra da categoria de venda', value: '' }}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Código EAN/GTIN</label>
                    <input
                        type="text"
                        className="form-control"
                        name="barCode"
                        value={formData.barCode || ''}
                        onChange={handleInputChange}
                        maxLength={14}
                        required
                        autoComplete="off"
                    />
                </div>
                <div className="mb-0">
                    <label className="form-label">Código interno (SKU)</label>
                    <input
                        type="text"
                        className="form-control"
                        name="sku"
                        value={formData.sku || ''}
                        onChange={handleInputChange}
                        maxLength={20}
                        required
                        autoComplete="off"
                    />
                </div>
            </div>

            <div className="mb-3 p-3 border rounded">
                <h5 style={{ marginBottom: '1em' }}>Preço</h5>
                <CurrencyInput
                    id="input-preco"
                    name="currentPrice"
                    placeholder="R$ 0,00"
                    defaultValue={formData.currentPrice}
                    decimalsLimit={2}
                    fixedDecimalLength={2}
                    decimalSeparator=","
                    groupSeparator="."
                    prefix="R$ "
                    className="form-control"
                    required
                    autoComplete="off"
                    onValueChange={(value, name) => handleCurrencyChange(value, name)}
                />
            </div>
        </div>
    );

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-3">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h3 className="mb-3">{item.id ? 'Editar Item' : 'Novo Item'}</h3>
                        <form className="mt-3">
                            {renderGeneralData()}

                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                                disabled={!item.id? false: !isFormChanged? true: false}
                            >
                                <FontAwesomeIcon icon={faSave} /> Salvar
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                        </form>
                    </div>
                ) : null}
            </div>
        </CSSTransition>
    );
}

export default ItemEdit;
