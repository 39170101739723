import React from 'react';

function Profile() {
  return (
    <div>
      <h2>Profile Page</h2>
      <p>This is an example profile page content.</p>
    </div>
  );
}

export default Profile;
