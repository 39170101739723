import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import NotificationWebSocket from './components/NotificationWebSocket';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Login from './components/Login';
import Home from './components/Home';
import Test from './components/Test';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/AuthContext';
import Navbar from './components/NavBar';
import Profile from './components/Profile';
import User from './components/User';
import ItemGroupList from './components/ItemGroupList';
import ItemGroupEdit from './components/ItemGroupEdit';
import SalesCategoryList from './components/SalesCategoryList';
import SalesCategoryEdit from './components/SalesCategoryEdit';
import ItemList from './components/ItemList';
import ItemEdit from './components/ItemEdit';
import UnitMeasurementList from './components/UnitMeasurementList';
import UnitMeasurementEdit from './components/UnitMeasurementEdit';
import StockLocationList from './components/StockLocationList';
import StockLocationEdit from './components/StockLocationEdit';
import CompanySettings from './components/CompanySettings';
import CompanySelection from './components/CompanySelection';
import SupplierList from './components/SupplierList';
import SupplierEdit from './components/SupplierEdit';
import CustomerList from './components/CustomerList';
import CustomerEdit from './components/CustomerEdit';
import CarrierList from './components/CarrierList';
import CarrierEdit from './components/CarrierEdit';
import SupplierInvoice from './components/SupplierInvoice';
import TaxRuleList from './components/TaxRuleList';
import TaxRuleEdit from './components/TaxRuleEdit';
import InvoiceList from './components/InvoiceList';
import InvoiceEdit from './components/InvoiceEdit';
import InvoiceReceivedList from './components/InvoiceReceivedList';
import CompanyName from './components/CompanyName';
import Alert from './components/Alert';
import './assets/styles/App.css';

function App() {
  //const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <Router>
      <AuthProvider>
        <AppContent  />
      </AuthProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login' || location.pathname === '/register';
  const isCompanySelection = location.pathname === '/company/selection';

  return (
    <div className={isLoginRoute || isCompanySelection ? "app-background" : ""}>
      {!isLoginRoute && !isCompanySelection && <NotificationWebSocket />}
      {!isLoginRoute && <Navbar />}
      {/*</div><div className={`app-layout ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>*/}
      <div className={`app-layout`}>
        <div className={isLoginRoute || isCompanySelection ? "container-login" : "container"}>
          {!isLoginRoute && !isCompanySelection && <CompanyName />}
          <Alert /> {}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/test" element={<Test />} />
              <Route path="/users" element={<User />} />
              <Route path="/items" element={<ItemList />} />
              <Route path="/items/:id" element={<ItemEdit />} />
              <Route path="/items/groups" element={<ItemGroupList />} />
              <Route path="/items/groups/:id" element={<ItemGroupEdit />} />
              <Route path="/sales-category" element={<SalesCategoryList />} />
              <Route path="/sales-category/:id" element={<SalesCategoryEdit />} />
              <Route path="/unit-measurements" element={<UnitMeasurementList />} />
              <Route path="/unit-measurements/:id" element={<UnitMeasurementEdit />} />
              <Route path="/stock-locations" element={<StockLocationList />} />
              <Route path="/stock-locations/:id" element={<StockLocationEdit />} />
              <Route path="/customers" element={<CustomerList />} />
              <Route path="/customers/:id" element={<CustomerEdit />} />
              <Route path="/suppliers" element={<SupplierList />} />
              <Route path="/suppliers/:id" element={<SupplierEdit />} />
              <Route path="/suppliers/:id/invoices" element={<SupplierInvoice />} />
              <Route path="/carriers" element={<CarrierList />} />
              <Route path="/carriers/:id" element={<CarrierEdit />} />
              <Route path="/tax-rules" element={<TaxRuleList />} />
              <Route path="/tax-rules/:id" element={<TaxRuleEdit />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/company-settings" element={<CompanySettings />} />
              <Route path="/company/selection" element={<CompanySelection />} />
              <Route path="/invoices/outgoing" element={<InvoiceList />} />
              <Route path="/invoices/outgoing/:id" element={<InvoiceEdit />} />
              <Route path="/invoices/incoming" element={<InvoiceReceivedList />} />
            </Route>
            <Route path="/" element={<Navigate to="/home" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
