import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortUp, faSortDown, faPencil, faTrash, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paginator } from 'primereact/paginator';
import ConfirmationModal from './ConfirmationModal';
import { CSSTransition } from 'react-transition-group';

function SupplierList() {
    const {setAlert, axiosConfigured } = useContext(AuthContext);
    const [suppliers, setSuppliers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortField, setSortField] = useState('legalName');
    const [sortOrder, setSortOrder] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [supplierToDelete, setSupplierToDelete] = useState(null);
    const hasFetched = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            const state = location.state;
            if (state) {
                setSearchTerm(state.searchTerm || '');
                setPage(state.page || 0);
                setRows(state.rows || 10);
                setSortField(state.sortField || 'legalName');
                setSortOrder(state.sortOrder || 1);
            }
            fetchSuppliers(state?.page || page, state?.rows || rows, state?.sortField || sortField, state?.sortOrder || sortOrder, state?.searchTerm || searchTerm);
            hasFetched.current = true;
        }
    }, [axiosConfigured, location.state, page, rows, searchTerm, sortField, sortOrder]);

    const fetchSuppliers = async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/suppliers', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const supplierData = response.data.content || [];
            setSuppliers(supplierData);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar fornecedores', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        fetchSuppliers(page, rows, sortField, sortOrder, searchTerm);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 1 ? -1 : 1;
        setSortField(field);
        setSortOrder(order);
        fetchSuppliers(page, rows, field, order, searchTerm);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 1 ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const onPageChange = (event) => {
        setPage(event.page);
        setRows(event.rows);
        fetchSuppliers(event.page, event.rows, sortField, sortOrder, searchTerm);
    };

    const handleEditSupplier = async (supplier) => {
        try {
            setInProp(false);
            setTimeout(() => {
                navigate(`/suppliers/${supplier.id}`, {
                    state: { supplier, searchTerm, page, rows, sortField, sortOrder },
                });
            }, 300);
        } catch (error) {
            console.error('Erro ao buscar fornecedor atualizado', error);
            setAlert({ type: 'danger', message: 'Erro ao carregar o fornecedor atualizado' });
        }
    };

    const handleDeleteSupplier = async () => {
        try {
            await axios.delete(`/api/suppliers/${supplierToDelete.id}`);
            setAlert({ type: 'success', message: 'Fornecedor excluído com sucesso' });
            setShowConfirmModal(false);
            fetchSuppliers(page, rows, sortField, sortOrder, searchTerm);
        } catch (error) {
            console.error('Erro ao excluir fornecedor', error);
            setShowConfirmModal(false);
        }
    };

    const confirmDeleteSupplier = (supplier) => {
        setSupplierToDelete(supplier);
        setShowConfirmModal(true);
    };

    const handleViewInvoices = (supplier) => {
        setInProp(false);
        setTimeout(() => {
            navigate(`/suppliers/${supplier.id}/invoices`, {
                state: { supplier, searchTerm, page, rows, sortField, sortOrder },
            });
        }, 300);
    };

    const formatCNPJ = (cnpj) => {
        if (!cnpj) return '';
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    };

    const formatCPF = (cpf) => {
        if (!cpf) return '';
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
            <div>
                <h3 className="mb-3 mt-3">Fornecedores</h3>

                <div className="d-flex justify-content-between mb-3">
                    <button className="btn btn-primary" onClick={() => navigate('/suppliers/new', { state: { searchTerm, page, rows, sortField, sortOrder } })}>
                        <span className="d-none d-md-inline">Novo Fornecedor</span>
                        <span className="d-inline d-md-none">Novo</span>
                    </button>
                    <div className="input-group search-size" style={{ maxWidth: '300px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                        <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : suppliers.length > 0 ? (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('legalName')} className='sortable-column'>Nome {getSortIcon('legalName')}</th>
                                    <th className='sortable-column d-none d-xl-table-cell'>CNPJ/CPF</th>
                                    <th onClick={() => handleSort('city.name')} className='sortable-column d-none d-xxl-table-cell'>Cidade/UF {getSortIcon('city.name')}</th>
                                    <th onClick={() => handleSort('active')} className='sortable-column d-none d-md-table-cell'>Ativo {getSortIcon('active')}</th>
                                    <th className='text-end'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                            {suppliers.map((supplier) => (
                                    <tr key={supplier.id}>
                                        <td className="ellipsis">{supplier.legalName}</td>
                                        <td className="d-none d-xl-table-cell">{supplier.supplierType === 'PESSOA_FISICA' ? formatCPF(supplier.cpf) : formatCNPJ(supplier.cnpj)}</td>
                                        <td className="d-none d-xxl-table-cell">{supplier.city ? `${supplier.city.name} / ${supplier.city.uf}` : 'N/A'}</td>
                                        <td className="d-none d-md-table-cell">{supplier.active ? 'Sim' : 'Não'}</td>
                                        <td className='text-end'>
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title="Editar" onClick={() => handleEditSupplier(supplier)}>
                                                <FontAwesomeIcon icon={faPencil} />
                                            </button>
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title="Excluir" onClick={() => confirmDeleteSupplier(supplier)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            <button className="btn btn-sm border-secondary custom-btn" title="Notas Fiscais" onClick={() => handleViewInvoices(supplier)}>
                                                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                        <Paginator
                            first={page * rows}
                            rows={rows}
                            totalRecords={totalRecords}
                            rowsPerPageOptions={[10, 20, 50]}
                            onPageChange={onPageChange}
                        />
                    </>
                ) : (
                    <div className="no-data">
                        <p>Ainda não há dados por aqui, ou sua pesquisa não retornou resultados.</p>
                    </div>
                )}

                <ConfirmationModal
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                    onConfirm={handleDeleteSupplier}
                    title="Confirmar Exclusão"
                    message={`Tem certeza que deseja excluir o fornecedor ${supplierToDelete?.legalName}?`}
                />
            </div>
        </CSSTransition>
    );
}

export default SupplierList;
