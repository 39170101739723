// Navbar.js

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt, faCircleUser, faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/logo-transparent.webp';
import '../assets/styles/NavBar.css';

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    // Limpa o listener quando o componente for desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

function Navbar() {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { auth, logout } = useContext(AuthContext);
  const userMenuRef = useRef(null);
  const navbarRef = useRef(null);
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login' || location.pathname === '/register';
  const isCompanySelectionRoute = location.pathname === '/company/selection';
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 992;

  const [openMenu, setOpenMenu] = useState(null);

  const toggleUserMenu = () => setShowUserMenu(!showUserMenu);
  const closeUserMenu = () => setShowUserMenu(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  const handleLogout = () => {
    logout(true, false);
    window.location.href = '/login';
    closeUserMenu();
  };

  const handleProfile = () => {
    window.location.href = '/profile';
    closeUserMenu();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        closeUserMenu();
      }
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setOpenMenu(null);
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuItemClick = () => {
    setOpenMenu(null);
    setShowMenu(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-custom" ref={navbarRef}>
      <div className="container-fluid">
      {isCompanySelectionRoute || isMobile? (
          <span className="navbar-brand disabled">
            <img src={logo} alt="Simpletize" width="35" height="auto" className="d-inline-block align-top" />
            Simpletize
          </span>
        ) : (
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Simpletize" width="35" height="auto" className="d-inline-block align-top" />
            Simpletize
          </Link>
        )}
        {!isLoginRoute && !isCompanySelectionRoute && (
          <>
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            {(isMobile && !isCompanySelectionRoute) && (
            <div className="d-flex align-items-center ms-auto">
              <div className="user-menu" ref={userMenuRef}>
                <FontAwesomeIcon
                  icon={faCircleUser}
                  size="2x"
                  className="user-icon"
                  onClick={toggleUserMenu}
                  style={{ cursor: 'pointer' }}
                />
                {showUserMenu && (
                  <div className="user-menu-dropdown">
                    <p><strong>{auth.userName}</strong></p>
                    <p>{auth.loginUser}</p>
                    {!isCompanySelectionRoute && (
                      <div className="dropdown-item" onClick={handleProfile} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faCog} /> Configuração do perfil
                      </div>
                    )}
                    <div className="dropdown-item" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faSignOutAlt} /> Sair
                    </div>
                  </div>
                )}
              </div>
            </div>
            )}
            <div className={`collapse navbar-collapse ${showMenu ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav ms-3">
                <li className="nav-item">
                  <Link className="nav-link" to="/home" onClick={handleMenuItemClick}>Dashboard</Link>
                </li>
                <li className="nav-item dropdown">
                  <div
                    className={`nav-link dropdown-toggle ${openMenu === 'documents' ? 'show' : ''}`}
                    role="button"
                    onClick={() => setOpenMenu(openMenu === 'documents' ? null : 'documents')}
                    style={{ position: 'relative', '::after': { content: 'none' } }}
                  >
                    Documentos fiscais
                    <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                  </div>
                  {openMenu === 'documents' && (
                    <div className="dropdown-menu show">
                      <Link className="dropdown-item" to="/invoices/incoming" onClick={handleMenuItemClick}>Notas Fiscais - Entrada</Link>
                      <Link className="dropdown-item" to="/invoices/outgoing" onClick={handleMenuItemClick}>Notas Fiscais - Saída</Link>
                    </div>
                  )}
                </li>
                {/* EXEMPLO COM SUBMENU
                <li className="nav-item dropdown">
                  <div
                    className={`nav-link dropdown-toggle ${openMenu === 'cadastros' ? 'show' : ''}`}
                    role="button"
                    onClick={() => setOpenMenu(openMenu === 'cadastros' ? null : 'cadastros')}
                  >
                    Cadastros
                    <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                  </div>
                  {openMenu === 'cadastros' && (
                    <div className="dropdown-menu show">
                      <div className="dropdown-submenu">
                        <Link className="dropdown-item" to="/customers" onClick={handleMenuItemClick}>
                          Clientes
                          <FontAwesomeIcon icon={faChevronRight} className="ms-1 text-end" style={{ fontSize: '65%' }} />
                        </Link>
                        <div className="dropdown-submenu-content">
                          <Link className="dropdown-item" to="/customers/new" onClick={handleMenuItemClick}>Novo Cliente</Link>
                          <Link className="dropdown-item" to="/customers/groups" onClick={handleMenuItemClick}>Grupos de Clientes</Link>
                        </div>
                      </div>
                      <Link className="dropdown-item" to="/suppliers" onClick={handleMenuItemClick}>Fornecedores</Link>
                      <Link className="dropdown-item" to="/tax-rules" onClick={handleMenuItemClick}>Regras Fiscais</Link>
                      <Link className="dropdown-item" to="/items/groups" onClick={handleMenuItemClick}>Grupos de Itens</Link>
                      <Link className="dropdown-item" to="/sales-category" onClick={handleMenuItemClick}>Categorias de Vendas</Link>
                      <Link className="dropdown-item" to="/items" onClick={handleMenuItemClick}>Itens</Link>
                      <Link className="dropdown-item" to="/unit-measurements" onClick={handleMenuItemClick}>Unidades de Medida</Link>
                      <Link className="dropdown-item" to="/carriers" onClick={handleMenuItemClick}>Transportadoras</Link>
                    </div>
                  )}
                </li>*/}
                <li className="nav-item dropdown">
                  <div
                    className={`nav-link dropdown-toggle ${openMenu === 'cadastros' ? 'show' : ''}`}
                    role="button"
                    onClick={() => setOpenMenu(openMenu === 'cadastros' ? null : 'cadastros')}
                  >
                    Cadastros
                    <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                  </div>
                  {openMenu === 'cadastros' && (
                    <div className="dropdown-menu show">
                      <Link className="dropdown-item" to="/customers" onClick={handleMenuItemClick}>Clientes</Link>
                      <Link className="dropdown-item" to="/suppliers" onClick={handleMenuItemClick}>Fornecedores</Link>
                      <Link className="dropdown-item" to="/tax-rules" onClick={handleMenuItemClick}>Regras Fiscais</Link>
                      <Link className="dropdown-item" to="/items/groups" onClick={handleMenuItemClick}>Grupos de Itens</Link>
                      <Link className="dropdown-item" to="/sales-category" onClick={handleMenuItemClick}>Categorias de Vendas</Link>
                      <Link className="dropdown-item" to="/items" onClick={handleMenuItemClick}>Itens</Link>
                      <Link className="dropdown-item" to="/unit-measurements" onClick={handleMenuItemClick}>Unidades de Medida</Link>
                      <Link className="dropdown-item" to="/carriers" onClick={handleMenuItemClick}>Transportadoras</Link>
                    </div>
                  )}
                </li>
                <li className="nav-item dropdown">
                  <div
                    className={`nav-link dropdown-toggle ${openMenu === 'admin' ? 'show' : ''}`}
                    role="button"
                    onClick={() => setOpenMenu(openMenu === 'admin' ? null : 'admin')}
                  >
                    Administração
                    <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                  </div>
                  {openMenu === 'admin' && (
                    <div className="dropdown-menu show">
                      <Link className="dropdown-item" to="/company-settings" onClick={handleMenuItemClick}>Conta/Loja</Link>
                      <Link className="dropdown-item" to="/users" onClick={handleMenuItemClick}>Usuários</Link>
                    </div>
                  )}
                </li>
              </ul>
              {(!isMobile && !isCompanySelectionRoute) && (
              <div className="d-flex align-items-center ms-auto">
                <div className="user-menu" ref={userMenuRef}>
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    size="2x"
                    className="user-icon"
                    onClick={toggleUserMenu}
                    style={{ cursor: 'pointer' }}
                  />
                  {showUserMenu && (
                    <div className="user-menu-dropdown">
                      <p><strong>{auth.userName}</strong></p>
                      <p>{auth.loginUser}</p>
                      {!isCompanySelectionRoute && (
                        <div className="dropdown-item" onClick={handleProfile} style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon icon={faCog} /> Configuração do perfil
                        </div>
                      )}
                      <div className="dropdown-item" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faSignOutAlt} /> Sair
                      </div>
                    </div>
                  )}
                </div>
              </div>
              )}
            </div>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
