import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ManifestationConfirm({ show, onHide, onConfirm, title, message }) {
    const [justification, setJustification] = useState('');

    const handleJustificationChange = (e) => {
        setJustification(e.target.value);
    };

    const handleConfirm = () => {
        onConfirm(justification);
        setJustification('');
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Manifestação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
                <Form.Group className="mt-0">
                    <Form.Label>Justificativa para a manifestação</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Digite a justificativa"
                        value={justification}
                        onChange={handleJustificationChange}
                        maxLength={255}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancelar</Button>
                <Button
                    className='btn btn-primary'
                    onClick={handleConfirm}
                    disabled={!justification.trim()}
                >
                    Enviar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ManifestationConfirm;
