import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function formatDateMonthYear(date) {
    const options = { month: 'long', year: 'numeric' };
    return new Date(date).toLocaleDateString('pt-BR', options);
}

function formatCnpj(cnpj) {
    return cnpj
        ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
        : 'N/A';
}

function formatPhone(phone) {
    if (!phone) return 'N/A';
    const cleaned = phone.replace(/[^\d]/g, ''); // Remove tudo que não é número
    if (cleaned.length === 10) {
        return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return phone;
}

function ConfirmationCnpj({ show, onHide, onConfirm, cnpjData }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmação de CNPJ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {cnpjData ? (
                    <>
                        <p><strong>Razão Social:</strong> {cnpjData.legalName || 'N/A'}</p>
                        <p><strong>Nome Fantasia:</strong> {cnpjData.name || 'N/A'}</p>
                        <p><strong>CNPJ:</strong> {formatCnpj(cnpjData.cnpj)}</p>
                        <p><strong>Simples Nacional:</strong> {cnpjData.isSimples? 'Sim': 'Não'}</p>
                        <p><strong>Email:</strong> {cnpjData.email || 'N/A'}</p>
                        <p><strong>Telefone:</strong> {formatPhone(cnpjData.phone1)}</p>
                        <p><strong>Endereço:</strong></p>
                        <ul>
                            <li><strong>Rua:</strong> {cnpjData.street || 'N/A'}, {cnpjData.number || 'N/A'}, {cnpjData.complement || 'N/A'}</li>
                            <li><strong>Bairro:</strong> {cnpjData.neighborhood || 'N/A'}</li>
                            <li><strong>Cidade:</strong> {cnpjData.city?.name || 'N/A'} - {cnpjData.city?.uf || 'N/A'}</li>
                            <li><strong>CEP:</strong> {cnpjData.zipCode || 'N/A'}</li>
                        </ul>
                        <p><strong>Atualizado:</strong> {cnpjData.updatedUntil ? formatDateMonthYear(cnpjData.updatedUntil) : 'N/A'}</p>
                    </>
                ) : (
                    <p>Carregando informações do CNPJ...</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Não quero utilizar</Button>
                <Button variant="primary" onClick={onConfirm}>Desejo utilizar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationCnpj;
